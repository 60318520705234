import * as React from 'react'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Grid2 as Grid, Stack, Typography } from '@mui/material'
import InformationPopper from '@local/src/Components/InformationPopper'

import { IAgePeriod, IMyAgeContent } from '../MyAge.types'

interface IAgeAmount {
  totalLon: number
  maxDagsBeloppText: string
  beloppHeader: string
  agePerioderAggregerat: IAgePeriod[]
  content: IMyAgeContent
}

const AgeAmount: React.FunctionComponent<
  React.PropsWithChildren<IAgeAmount>
> = ({
  totalLon,
  content,
  maxDagsBeloppText,
  beloppHeader,
  agePerioderAggregerat,
}) => (
  <Grid container spacing={1} mb={1}>
    <Grid size={{ xs: 12 }}>
      <Typography variant="h3">{beloppHeader}</Typography>
    </Grid>
    <Grid size={{ xs: 12 }}>
      <Typography variant="body2">
        {content.lonText}
        <br />
        <strong>{formatCurrencySEK(totalLon)}</strong>
      </Typography>
    </Grid>

    {agePerioderAggregerat.map((period) => (
      <Grid
        size={{ xs: 12 }}
        key={`${period.startDag}-${period.slutDag}-${period.dagPenning}`}
      >
        <Stack direction="row">
          <Typography variant="body2">
            {maxDagsBeloppText.replace(
              '{period}',
              `${period.startDag}-${period.slutDag}`
            )}

            <br />
            <strong>{formatCurrencySEK(period.dagPenning)}</strong>
          </Typography>

          {period.dagPenning === 0 && (
            <InformationPopper
              text={content.informationNollKrPerDagPopperText}
              id={`Popper-${period.startDag}`}
            />
          )}
        </Stack>
      </Grid>
    ))}
  </Grid>
)

export default AgeAmount
