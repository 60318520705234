import { useSelector } from '@local/Store/storeContext'
import { withHooks } from '@local/Utils/Hooks'

import {
  agePerioderAggregerat as agePerioderAggregeratSelector,
  arendeNummer as arendeNummerSelector,
  maxErsattningsDagar as maxErsattningsDagarSelector,
  kanSkrivaUtAgeBeslut as kanSkrivaUtAgeBeslutSelector,
  skrivUtBeslutLoading as skrivUtBeslutLoadingSelector,
  skrivUtBeslut as skrivUtBeslutSelector,
  startDatum as startDatumSelector,
  totalLon as totalLonSelector,
  utbetaldaDagar as utbetaldaDagarSelector,
} from '../Age.selectors'

import MyAge from './MyAge'

const MyAgeSelectors = () => ({
  agePerioderAggregerat: useSelector(agePerioderAggregeratSelector),
  arendeNummer: useSelector(arendeNummerSelector),
  kanSkrivaUtAgeBeslut: useSelector(kanSkrivaUtAgeBeslutSelector),
  maxErsattningsDagar: useSelector(maxErsattningsDagarSelector),
  skrivUtBeslut: useSelector(skrivUtBeslutSelector),
  skrivUtBeslutLoading: useSelector(skrivUtBeslutLoadingSelector),
  startDatum: useSelector(startDatumSelector),
  totalLon: useSelector(totalLonSelector),
  utbetaldaDagar: useSelector(utbetaldaDagarSelector),
})

export default withHooks({
  MyAgeSelectors,
})(MyAge)
