import * as Yup from 'yup'

const validationSchema = Yup.lazy(() =>
  Yup.object().shape<{ godkann: boolean }>({
    godkann: Yup.bool().oneOf(
      [true],
      'Du måste markera att du läst och förstått att du hädanefter inte får stöd från TRR'
    ),
  })
)

export default validationSchema
