/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react'
import { Provider } from 'react-redux'
import {
  AppShellDataProps,
  AppShellDataProvider,
  getIdToken,
} from '@trr/app-shell-data'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { sv } from 'date-fns/locale'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { GTMTracker } from '@trr/gtm-tracking'

import Store from './Store/configureStore'
import { AgeContext } from './Store/storeContext'
import MainContent from './MainContent'
import SignalRWrapperHelper from './Utils/Helpers/SignalRWrapper'

injectStyle()

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => {
  const theme = createTheme(themeOptions, defaultLocale)
  const id_token = getIdToken()

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
        <AppShellDataProvider value={appShellData}>
          <Provider store={Store} context={AgeContext}>
            {id_token ? (
              <SignalRWrapperHelper>
                <GTMTracker mfName="age">
                  <MainContent />
                </GTMTracker>
              </SignalRWrapperHelper>
            ) : (
              <GTMTracker mfName="age">
                <MainContent />
              </GTMTracker>
            )}
          </Provider>
        </AppShellDataProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
