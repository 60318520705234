import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'

import { atertagenUppsagning, setPending } from '../../Age.actions'

import AtertagenUppsagning from './AtertagenUppsagning'

const connectedComponent = connect(
  () => ({}),
  { atertagenUppsagning, setPending },
  null,
  { context: AgeContext }
)(AtertagenUppsagning)

export default connectedComponent
