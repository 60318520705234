import React, { FC } from 'react'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Link } from '@mui/material'

interface IExternalLink {
  href: string
  text: string
}

export const ExternalLink: FC<IExternalLink> = ({
  href,
  text,
}: IExternalLink) => (
  <Box mt={3} mb={4}>
    <div>
      <OpenInNewIcon style={{ height: '22px' }} color="primary" />
      <Link
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {text}
      </Link>
    </div>
  </Box>
)
