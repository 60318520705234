import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { AgeContext } from '@local/Store/storeContext'

import {
  postRapporteraEkonomisktForetagsstod,
  setPending,
} from '../../Age.actions'
import { inkomstUppgift, foretagNamn, startDatum } from '../../Age.selectors'

import EkonomisktForetagsstod from './EkonomisktForetagsstod'

const connectedComponent = connect(
  (state: RootState) => ({
    inkomstUppgift: inkomstUppgift(state),
    foretagNamn: foretagNamn(state),
    startDatumAge: new Date(startDatum(state)),
  }),
  { postRapporteraEkonomisktForetagsstod, setPending },
  null,
  { context: AgeContext }
)(EkonomisktForetagsstod)

export default connectedComponent
