import { IInkomstuppgifter } from '@local/src/Age/MyAge/MyAge.types'

export const sumSalary = ({
  manadsLon,
  provision,
  jour,
}: IInkomstuppgifter): number =>
  isNaN(manadsLon + provision + jour)
    ? 0
    : Number(manadsLon) + Number(provision) + Number(jour)
