import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isNil } from 'ramda'
import * as Yup from 'yup'

import { IAndraSkatteavdragFormValues } from './AndraSkatteavdrag.types'

const validationSchema = Yup.lazy((values: IAndraSkatteavdragFormValues) =>
  Yup.object().shape({
    skattesats: Yup.mixed().test(
      'skattesats',
      'Du måste fylla i en giltig procentsats',
      (value) => value !== null && value >= 0 && value <= 100
    ),
    files: Yup.mixed().test(
      'files',
      'Du måste bifoga beslut om skattejämkning',
      (files: IUniqueFile[]) => {
        const { skattesats } = values
        const isValidSkattesats = skattesats !== null && skattesats >= 30
        const isValidFile = !isNil(files[0])

        return isValidSkattesats || isValidFile
      }
    ),
  })
)

export default validationSchema
