import { useSelector } from '@local/Store/storeContext'
import { withHooks } from '@local/Utils/Hooks'

import {
  akasseBeloppPerioder as akasseBeloppPerioderSelector,
  dagPenningAkassaPerioder as dagPenningAkassaPerioderSelector,
  inkomstbasbeloppPerioder as inkomstbasbeloppPerioderSelector,
} from './Raknesnurra.selectors'
import Raknesnurra from './Raknesnurra'

const RaknesnurraSelectors = () => ({
  dagPenningAkassaPerioder: useSelector(dagPenningAkassaPerioderSelector),
  inkomstbasbeloppPerioder: useSelector(inkomstbasbeloppPerioderSelector),
  akasseBeloppPerioder: useSelector(akasseBeloppPerioderSelector),
})

export default withHooks({
  RaknesnurraSelectors,
})(Raknesnurra)
