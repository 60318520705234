import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'

import { rapporteraArbetssokandeAvbrott, setPending } from '../../Age.actions'

import ArbetssokandeAvbrott from './ArbetssokandeAvbrott'

const connectedComponent = connect(
  () => ({}),
  { rapporteraArbetssokandeAvbrott, setPending },
  null,
  { context: AgeContext }
)(ArbetssokandeAvbrott)

export default connectedComponent
