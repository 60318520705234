import * as Yup from 'yup'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isNil } from 'ramda'

const validationSchema = Yup.object().shape({
  files: Yup.mixed().test(
    'files',
    'Du måste bifoga en fil',
    (files: IUniqueFile[]) => !isNil(files[0])
  ),
  arbetssokandeIntygTyp: Yup.string().required(
    'Du behöver välja ett alternativ'
  ),
})

export default validationSchema
