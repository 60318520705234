import * as React from 'react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel } from '@mui/material'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAtertagenUppsagning,
  IDataAtertagenUppsagning,
} from './AtertagenUppsagning.types'
import validationSchema from './AtertagenUppsagning.schema'

const initialValues: IDataAtertagenUppsagning = {
  godkann: false,
}

const AtertagenUppsagning = ({
  formName,
  content,
  handleCancel,
  isExpanded,
  isPending,
  setPending,
  atertagenUppsagning,
}: IAtertagenUppsagning) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    atertagenUppsagning()
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema,
  })

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const isError = formik.touched.godkann && Boolean(formik.errors.godkann)

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <FormControl error={isError} variant="standard" fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.godkann}
              color="primary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="godkann"
            />
          }
          label={content.checkboxText}
        />
        {isError && (
          <FormErrorMessage>{formik.errors.godkann}</FormErrorMessage>
        )}
      </FormControl>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default AtertagenUppsagning
