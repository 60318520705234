import * as React from 'react'
import { useFormik } from 'formik'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useEffect } from 'react'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { trrDate } from '@local/src/Utils/Helpers/formatDate'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { addBusinessDays } from 'date-fns'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import { isNil } from 'ramda'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'
import { IMinaInkomstUppgifterFormContent } from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm.types'
import MinaInkomstUppgifterForm from '../Common/MinaInkomstUppgifterForm'
import { IInkomstuppgifter } from '../../MyAge/MyAge.types'

import {
  IForsakraArbetssokandeFormValues,
  IForsakraArbetssokande,
} from './ForsakraArbetssokande.types'
import validationSchema from './ForsakraArbetssokande.schema'
import getFormData from './ForsakraArbetssokande.helper'

const getInitialValues = (
  inkomstUppgift: IInkomstuppgifter
): IForsakraArbetssokandeFormValues => ({
  anstallningsForm: '',
  avbrottsOrsak: '',
  arbetssokandeStartDatum: null,
  avbrottStartDatum: null,
  forsakran: false,
  isAvbrytaArbetssokande: 'false',
  isInskrivenSomArbetssokande: 'true',
  inkomstUppgift,
})

const ForsakraArbetssokande = ({
  formName,
  startDatum,
  content,
  inkomstUppgift,
  handleCancel,
  foretagNamn,
  isExpanded,
  forsakraArbetssokande,
  setPending,
  isPending,
}: IForsakraArbetssokande) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values, foretagNamn)

    forsakraArbetssokande(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const initialValues = getInitialValues(inkomstUppgift)

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema: validationSchema({ content, inkomstUppgift, startDatum }),
  })

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const changeLoneRevisionsDatum = (value: Date) => {
    void formik.setFieldValue('inkomstUppgift.lonerevisionsDatum', value)
  }

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      {!inkomstUppgift?.harUppdateratLon && (
        <MinaInkomstUppgifterForm
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          handleChangeDate={changeLoneRevisionsDatum}
          values={formik.values.inkomstUppgift}
          content={content.inkomstUppgift as IMinaInkomstUppgifterFormContent}
          foretagNamn={foretagNamn}
          errors={formik.errors.inkomstUppgift}
        />
      )}

      <Grid container spacing={2} mt={2}>
        <Grid  size={{ xs: 12 }} pb={2}>
          <Typography variant="body1">{content.informationText}</Typography>
        </Grid>

        {!inkomstUppgift?.harUppdateratLon && (
          <Grid size={{ xs: 12 }}>
            <Typography variant="h6" pt={4} pb={4}>
              {content.additionalInformationHeader}
            </Typography>
          </Grid>
        )}

        <Grid size={{ xs: 12 }}>
          <RadioGroup
            name="isInskrivenSomArbetssokande"
            value={formik.values.isInskrivenSomArbetssokande}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <Typography variant="body1">
              {content.inskrivenSomArbetssokandeHeader}
            </Typography>

            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Ja"
              data-testid="isInskrivenSomArbetssokande"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Nej"
              data-testid="notInskrivenSomArbetssokande"
            />
          </RadioGroup>
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          {formik.values.isInskrivenSomArbetssokande === 'true' ? (
            <DatePicker
              name="arbetssokandeStartDatum"
              testId="arbetssokandeStartDatum"
              value={formik.values.arbetssokandeStartDatum}
              onChange={customSetFieldValue(
                formik.setFieldValue,
                'arbetssokandeStartDatum'
              )}
              label={content.startDatumText}
              minDate={trrDate(startDatum)}
              maxDate={trrDate()}
              errorMessage={
                formik.touched.arbetssokandeStartDatum &&
                formik.errors.arbetssokandeStartDatum !== ''
                  ? (formik.errors.arbetssokandeStartDatum as string)
                  : ''
              }
            />
          ) : (
            <Box
              pt={1}
              pb={2}
              sx={(theme) => ({
                color: theme.palette.error.main,
              })}
            >
              <HTMLMapper
                body={
                  content.inteInskrivenSomArbetssokandeText ??
                  'För att få AGE måste du vara inskriven hos Arbetsförmedlingen.'
                }
              />
            </Box>
          )}
        </Grid>

        <Grid size={{ xs: 12 }} mt={4}>
          <RadioGroup
            value={formik.values.isAvbrytaArbetssokande}
            name="isAvbrytaArbetssokande"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <Typography variant="body1">
              {content.avbrottArbetssokandeHeader}
            </Typography>

            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Ja"
              data-testid="isAvbrytaArbetssokande"
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Nej"
              data-testid="notAvbrytaArbetssokande"
            />
          </RadioGroup>
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          {formik.values.isAvbrytaArbetssokande === 'true' && (
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <DatePicker
                  label={content.avbrottsOrsakFromDatumHeader}
                  value={formik.values.avbrottStartDatum}
                  name="avbrottStartDatum"
                  errorMessage={
                    formik.touched.avbrottStartDatum &&
                    formik.errors.avbrottStartDatum !== ''
                      ? (formik.errors.avbrottStartDatum as string)
                      : ''
                  }
                  onChange={customSetFieldValue(
                    formik.setFieldValue,
                    'avbrottStartDatum'
                  )}
                  maxDate={addBusinessDays(
                    formik.values.avbrottStartDatum ?? 0,
                    30
                  )}
                  testId="avbrottStartDatum"
                />
              </Grid>

              <Grid size={{ xs: 12 }}/>

              <Grid size={{ xs: 12, sm: 6 }}>
                <RadioGroup
                  name="avbrottsOrsak"
                  value={formik.values.avbrottsOrsak}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <Typography variant="body2">
                    {content.orsakBulletPointHeader}
                  </Typography>

                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Ny anställning"
                    data-testid="avbrottsOrsak-2"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Pension"
                    data-testid="avbrottsOrsak-1"
                  />
                  <FormControlLabel
                    value="7"
                    control={<Radio />}
                    label="Annat avbrott"
                    data-testid="avbrottsOrsak-7"
                  />

                  <FormHelperText error={Boolean(formik.touched.avbrottsOrsak)}>
                    {formik.errors.avbrottsOrsak}
                  </FormHelperText>
                </RadioGroup>
              </Grid>

              <Grid size={{ xs: 12, sm: 6 }}/>
            </Grid>
          )}
        </Grid>

        <Grid size={{ xs: 12 }}/>

        <Grid size={{ xs: 12, sm: 6 }}>
          {formik.values.isAvbrytaArbetssokande === 'true' &&
            formik.values.avbrottsOrsak === '2' && (
              <FormControl fullWidth>
                <InputLabel id="anstallningsForm-label">
                  Anställningsform:
                </InputLabel>
                <Select
                  labelId="anstallningsForm-label"
                  label="Anställningsform:"
                  name="anstallningsForm"
                  value={formik.values.anstallningsForm}
                  onChange={(event: SelectChangeEvent) =>
                    void formik.setFieldValue(
                      'anstallningsForm',
                      event.target.value
                    )
                  }
                >
                  <MenuItem value="1">Tillsvidare</MenuItem>
                  <MenuItem value="2">Visstid</MenuItem>
                  <MenuItem value="3">Provanställning</MenuItem>
                  <MenuItem value="4">Projektanställning</MenuItem>
                </Select>

                {formik.errors.anstallningsForm?.length > 0 && (
                  <FormErrorMessage>
                    {formik.errors.anstallningsForm}
                  </FormErrorMessage>
                )}
              </FormControl>
            )}
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}/>

        <Grid size={{ xs: 12 }}>
          <FormControl
            error={formik.touched.forsakran && !isNil(formik.errors.forsakran)}
            variant="standard"
            fullWidth
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.forsakran}
                  color="primary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="forsakran"
                />
              }
              label={content.forsakraArbetssokandeCheckboxText}
            />
            {formik.touched.forsakran && !isNil(formik.errors.forsakran) && (
              <FormErrorMessage>{formik.errors.forsakran}</FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default ForsakraArbetssokande
