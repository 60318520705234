import { handleActions } from 'redux-actions'

import {
  hideSystemFeedback,
  showSystemFeedback,
} from './SystemFeedback.actions'

export interface ISystemFeedback {
  message: string
}

export interface ISystemFeedbackState {
  isVisible: boolean
  message: string
}

export const initialState: ISystemFeedbackState = {
  isVisible: false,
  message: '',
}

const systemFeedbackReducer = handleActions<
  ISystemFeedbackState,
  ISystemFeedback
>(
  {
    [showSystemFeedback.toString()]: (state, action) => ({
      ...state,
      isVisible: true,
      message: action.payload.message,
    }),
    [hideSystemFeedback.toString()]: (state) => ({
      ...state,
      isVisible: false,
      message: '',
    }),
  },
  initialState
)

export default systemFeedbackReducer
