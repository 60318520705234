import React from 'react'
import { Typography, Button, Grid2 as Grid } from '@mui/material'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { ISkrivUtBeslut } from './SkrivUtBeslut.types'
import SkrivUtBeslutPdf from './SkrivUtBeslutPdf'

type PDFDownloadLinkProps = {
  loading: boolean
}

const SkrivUtBeslut = ({
  content,
  skrivUtBeslut,
  disabled,
}: ISkrivUtBeslut): JSX.Element => (
  <Grid
    container
    spacing={2}
    mt={2}
    style={{
      alignItems: 'baseline',
    }}
  >
    <Grid>
      <Button variant="contained" disabled={disabled}>
        {disabled ? (
          <Typography variant="body2" fontStyle="italic">
            {content.downloadButtonText}
          </Typography>
        ) : (
          <PDFDownloadLink
            document={
              <SkrivUtBeslutPdf
                content={content}
                skrivUtBeslut={skrivUtBeslut}
              />
            }
            fileName="Beslut om Avgångsersättning (AGE).pdf"
            style={{ textDecoration: 'none', color: 'unset' }}
          >
            {/* @ts-expect-error-typescript can't handle correct format from react-pdf */}
            {({ loading }: PDFDownloadLinkProps) =>
              loading ? 'Laddar dokument...' : content.downloadButtonText
            }
          </PDFDownloadLink>
        )}
      </Button>
    </Grid>

    <Grid>
      <Typography variant="body2" mt={1} fontStyle="italic">
        {disabled
          ? content.laddaNerBeslutEjMojligtText
          : content.laddaNerBeslutText}
      </Typography>
    </Grid>
  </Grid>
)

export default SkrivUtBeslut
