import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch } from '@local/src/Store/storeContext'
import { RootState } from '@local/src/Store/configureStore'
import { useStateSelector } from '@local/src/Utils/useStateSelector'

import { hideSystemFeedback } from './SystemFeedback.actions'

export const defaultErrorMessage =
  'Den förändring du försökte genomföra på ditt ärende har misslyckats. Vänlig försök igen eller kontakta support'

const SystemFeedback: React.FunctionComponent = () => {
  const dispatch = useDispatch()

  const handleSnackbarClose = () => {
    dispatch(hideSystemFeedback())
  }

  const isVisible = useStateSelector(
    (state: RootState) => state.systemfeedback.isVisible
  )

  const message = useStateSelector(
    (state: RootState) => state.systemfeedback.message
  )

  if (!message) return <></>

  return (
    <Snackbar
      open={isVisible}
      autoHideDuration={10000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity="error"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SystemFeedback
