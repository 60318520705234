import { useEffect } from 'react'

const useResetForm = (
  isExpanded: boolean,
  resetForm: (values: unknown) => void,
  initialValues: unknown = {}
): void => {
  useEffect(() => {
    !isExpanded &&
      setTimeout(() => {
        resetForm({ values: initialValues })
      }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded])
}

export default useResetForm
