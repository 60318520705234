import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { FileUpload } from '@local/Components/FileUpload/FileUpload'
import { Box, Grid2 as Grid, TextField } from '@mui/material'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IAndraSkatteavdrag,
  IAndraSkatteavdragFormValues,
} from './AndraSkatteavdrag.types'
import validationSchema from './AndraSkatteavdrag.schema'
import { getFormData } from './AndraSkatteavdrag.helper'

export const AndraSkatteavdrag: FC<IAndraSkatteavdrag> = ({
  formName,
  arendeSkattesats,
  content,
  isExpanded,
  isPending,
  andraSkatteavdrag,
  setPending,
  handleCancel,
}) => {
  const initialValues = React.useMemo(
    () => ({
      skattesats: arendeSkattesats,
      files: [],
    }),
    [arendeSkattesats]
  )

  const customEventTracking = useTrackCustomClick()

  const formSubmit = (values: IAndraSkatteavdragFormValues) => {
    const formData = getFormData(values)
    andraSkatteavdrag(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema,
  })

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  const displayBilaga =
    formik.values.skattesats !== null && formik.values.skattesats < 30
  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  const isSkattesatsError = Boolean(
    formik.touched.skattesats && formik.errors.skattesats
  )
  const isFilesError = Boolean(formik.touched.files && formik.errors.files)

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper
          body={content.informationText}
          replaceValues={{ '{skattesats}': formik.values.skattesats }}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid size={{ xs:12, sm: 6 }}>
          <TextField
            fullWidth
            error={isSkattesatsError}
            helperText={formik.touched.skattesats && formik.errors.skattesats}
            label={content.inputFieldHeader}
            onChange={formik.handleChange}
            name="skattesats"
            onBlur={formik.handleBlur}
            value={formik.values.skattesats}
            type="number"
          />
        </Grid>
      </Grid>

      {displayBilaga && (
        <FileUpload
          isLoadingUpload={false}
          content={uploadContent}
          inputName={'files'}
          files={formik.values.files}
          setFieldValue={formik.setFieldValue}
          errorMessage={isFilesError ? String(formik.errors.files) : ''}
        />
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default AndraSkatteavdrag
