import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import FileUpload from '@local/Components/FileUpload'
import EpiFile from '@local/Components/EpiFile'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import { Box } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import { ILonegaranti, ILonegarantiFormValues } from './Lonegaranti.types'
import { getFormData } from './Lonegaranti.helper'
import validationSchema from './Lonegaranti.schema'

const initialValues: ILonegarantiFormValues = {
  files: [],
}

export const Lonegaranti = ({
  formName,
  content,
  handleCancel,
  isExpanded,
  isPending,
  lonegarantibeslut,
  setPending,
}: ILonegaranti) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values)
    lonegarantibeslut(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues: initialValues,
    validationSchema: validationSchema,
  })
  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentLabel
  )

  const isFilesError = Boolean(formik.touched.files && formik.errors.files)

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <EpiFile relLink={content.exampleFile} label={content.exampleFileLabel} />
      <FileUpload
        isLoadingUpload={false}
        content={uploadContent}
        inputName={'files'}
        files={formik.values.files}
        setFieldValue={formik.setFieldValue}
        errorMessage={isFilesError ? String(formik.errors.files) : ''}
      />
      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default Lonegaranti
