import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'
import { RootState } from '@local/Store/configureStore'

import { intygaAktivitetstod, setPending } from '../../Age.actions'
import { inkomstUppgift, foretagNamn } from '../../Age.selectors'

import IntygaAktivitetsstod from './IntygaAktivitetsstod'

const connectedComponent = connect(
  (state: RootState) => ({
    inkomstUppgift: inkomstUppgift(state),
    foretagNamn: foretagNamn(state),
  }),
  { intygaAktivitetstod, setPending },
  null,
  { context: AgeContext }
)(IntygaAktivitetsstod)

export default connectedComponent
