import * as Yup from 'yup'

const validationSchema = Yup.lazy(() =>
  Yup.object().shape({
    avbrottsDatum: Yup.date()
      .nullable()
      .required('Du behöver ange ett datum för avbrott')
      .typeError('Du behöver ange ett giltigt datum för avbrott'),
    anstallningsForm: Yup.string()
      .nullable()
      .when('avbrottsOrsak', {
        is: (orsak) => orsak === '2',
        then: (value: Yup.StringSchema) =>
          value.required('Du behöver ange en anställningsform'),
      }),
  })
)

export default validationSchema
