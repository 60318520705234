import {
  isValid,
  parse,
  isFuture,
  add,
  format,
  isAfter,
  addDays,
  differenceInBusinessDays,
} from 'date-fns'
import sv from 'date-fns/locale/sv'
import { trrDate } from '@local/Utils/Helpers/formatDate'

import { convertToDateWithoutTime } from '../Raknesnurra.helper'

import {
  AgePeriod,
  IAkasseBeloppPeriod,
  IBeraknadAge,
  IInkomstbasbeloppPeriod,
} from './BeraknaAge.types'

export const BeraknaMojligAge = ({
  manadslon,
  dagarPerManad,
  ersattningsniva,
  dagpenningAkassa,
}: {
  manadslon: number
  dagarPerManad: number
  ersattningsniva: number
  dagpenningAkassa: number
}): number =>
  Math.round(
    (manadslon / dagarPerManad) * (ersattningsniva / 100) - dagpenningAkassa
  )

export const BeraknaMaxAge = ({
  inkomstBasbelopp,
  inkomstBasbeloppFaktor,
  dagarPerManad,
  ersattningsniva,
  dagpenningAkassa,
}: {
  inkomstBasbelopp: number
  inkomstBasbeloppFaktor: number
  dagarPerManad: number
  ersattningsniva: number
  dagpenningAkassa: number
}): number =>
  Math.round(
    (inkomstBasbeloppFaktor * inkomstBasbelopp * (ersattningsniva / 100)) /
      dagarPerManad -
      dagpenningAkassa
  )

export const getAgePeriodStartDay = (agePeriod: AgePeriod) => {
  switch (agePeriod) {
    case 'AGE1':
      return 1

    case 'AGE2':
      return 131

    case 'AGE3':
      return 261
  }
}

const isAfterOrEqual = (date: Date, dateToCompare: Date) => {
  const isEqual = date.toDateString() === dateToCompare.toDateString()
  return isAfter(date, dateToCompare) || isEqual
}

export const getRetirementAge = (birthDate: Date): number => {
  const birthYear: number = birthDate.getFullYear()
  const birthMonth: number = birthDate.getMonth()
  const birthDay: number = birthDate.getDate()

  const birthdateAt65 = new Date(birthYear + 65, birthMonth, birthDay)
  const birthdateAt67 = new Date(birthYear + 67, birthMonth, birthDay)
  const breakDate2023 = new Date('2023-01-01')
  const breakDate2026 = new Date('2026-01-01')

  switch (true) {
    case birthYear <= 1957:
      return isAfterOrEqual(birthdateAt65, breakDate2023) ? 66 : 65
    case birthYear === 1958:
      return 66
    case birthYear >= 1959:
      return isAfterOrEqual(birthdateAt67, breakDate2026) ? 67 : 66
    default:
      return 67
  }
}

export const getPensionStart = (fodelseDatum: Date) => {
  const maxAlder = getRetirementAge(fodelseDatum)

  //pensionstart är den första dagen i månaden när man når pensionålder (enligt TRR)
  const pensionStart = new Date(
    fodelseDatum.getFullYear() + maxAlder,
    fodelseDatum.getMonth(),
    1
  )

  return pensionStart
}

export const getAgeStart = (sistaAnstallningsdag: Date) =>
  addDays(sistaAnstallningsdag, 1) //dagen efter sista anställningsdagen

export const getAgeEnd = (fodelseDatum: Date) => {
  const pensionStart = getPensionStart(fodelseDatum)
  const ageEnd = addDays(pensionStart, -1) //dagen innan pension

  return ageEnd
}

export const getAgeEndDay = (sistaAnstallningsdag: Date, birthdate: Date) => {
  const ageStart = add(sistaAnstallningsdag, { days: 1 })
  const ageEnd = getAgeEnd(birthdate)
  const ageEndDay = differenceInBusinessDays(ageEnd, ageStart)

  return ageEndDay
}

export const getTotalBelopp = (agePeriods: IBeraknadAge[]) => {
  let sum = 0
  agePeriods.forEach((x) => (sum = sum + x.totalBelopp))

  return sum
}

export const getQtyAgeDays = (agePeriods: IBeraknadAge[]) => {
  let days = 0
  agePeriods.forEach((x) => (days = days + x.lastDay - x.startDay + 1))

  return days
}

export const hasReducedDaysDueToPension = (
  agePeriods: IBeraknadAge[],
  birthdate: Date,
  sistaAnstallningsdag: Date
) => {
  const qtyAgeDays = getQtyAgeDays(agePeriods)
  const ageEndDay = getAgeEndDay(sistaAnstallningsdag, birthdate)
  const hasReducedDaysDueToPension = ageEndDay === qtyAgeDays

  return hasReducedDaysDueToPension
}

export const getMaxAkasseBeloppForDate = (
  day: number,
  date: Date,
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
) => {
  const ageDate = convertToDateWithoutTime(date)
  const akasseBeloppPeriod = akasseBeloppPerioder.find(
    (period) =>
      convertToDateWithoutTime(period.giltigtFrom) <= ageDate &&
      convertToDateWithoutTime(period.giltigtTom) >= ageDate &&
      day >= period.dayFrom &&
      day <= period.dayTo
  )

  if (akasseBeloppPeriod) return akasseBeloppPeriod.maxAkasseBelopp
}

export const GetInkomstbasbeloppPeriod = ({
  mojligaInkomstbasbelopp,
  uppsagningsar,
  agePeriod,
}: {
  mojligaInkomstbasbelopp: IInkomstbasbeloppPeriod[]
  uppsagningsar: number
  agePeriod: string
}): IInkomstbasbeloppPeriod => {
  const uppsagningsdatum = new Date(`${uppsagningsar}-01-01`)
  return mojligaInkomstbasbelopp.find(
    (mojligtInkomstbasbelopp) =>
      mojligtInkomstbasbelopp.agePeriod === agePeriod &&
      mojligtInkomstbasbelopp.giltigtFrom <= uppsagningsdatum &&
      mojligtInkomstbasbelopp.giltigtTom >= uppsagningsdatum
  )
}

export const GetBeloppPerDag = (
  mojligAge: number,
  maxAgeBelopp: number
): number => {
  const beloppPerDag = mojligAge <= maxAgeBelopp ? mojligAge : maxAgeBelopp
  return beloppPerDag < 0 ? 0 : beloppPerDag
}

export const GetTotalBelopp = (beloppPerDag: number, qtyDays: number): number =>
  beloppPerDag * qtyDays

export const convertFodelsedatumToDate = (fodelsedatum: string): Date => {
  fodelsedatum = fodelsedatum.replace(/^(\d{4})/, '$1-')
  fodelsedatum = fodelsedatum.replace(/-(\d{2})/, '-$1-')
  return trrDate(fodelsedatum)
}

export const IsCorrectFodelsedatum = (value: string): boolean => {
  const fodelsedatumString = value || ''
  const correctLength = fodelsedatumString?.length === 8
  const years = parseInt(fodelsedatumString.substring(0, 4))
  const month = parseInt(fodelsedatumString.substring(4, 6))
  const days = parseInt(fodelsedatumString.substring(6, 8))

  const parsedDate = parse(
    [years, month, days].join('-'),
    'yyyy-MM-dd',
    new Date(),
    { locale: sv }
  )
  return correctLength && isValid(parsedDate) && !isFuture(parsedDate)
}

export const getBirthDateByAge = (age: number): string => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - age)

  return format(new Date(date), 'yyyyMMdd')
}

export const getNumberWorkingDays = (
  sistaAnstallningsdag: Date,
  startDay: number,
  lastDay: number
) => {
  let numberWorkingDays = 0
  let currentDate = new Date(sistaAnstallningsdag)

  for (let i = startDay; i <= lastDay; i++) {
    currentDate = addDays(sistaAnstallningsdag, i)

    const isSunday = currentDate.getDay() === 0
    const isSaturday = currentDate.getDay() === 6
    const isWorkingDay = !isSunday && !isSaturday

    if (isWorkingDay) {
      numberWorkingDays++
    }
  }

  return numberWorkingDays
}

export const isSaturday = (date: Date) => date.getDay() === 6
export const isSunday = (date: Date) => date.getDay() === 0
export const isWorkingDay = (date: Date) => !isSunday(date) && !isSaturday(date)

export const formatDate = (date: Date) => format(new Date(date), 'yyyy-MM-dd')
