import { Typography } from '@mui/material'
import React from 'react'

export const SaknarAgeRattighet = ({
  saknarRattighet,
  information,
}: {
  saknarRattighet: boolean
  information: string
}): JSX.Element =>
  saknarRattighet && <Typography variant="body2">{information}</Typography>
