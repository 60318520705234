import { isEmpty, omit } from 'ramda'

import { IForsakraArbetssokandeFormValues } from './ForsakraArbetssokande.types'

const getFormData = (
  values: IForsakraArbetssokandeFormValues,
  foretagNamn: string
) => {
  const mergedData = {
    ...values,
    foretagNamn,
  }

  const formData = omit(
    [
      'isInskrivenSomArbetssokande',
      'forsakran',
      isEmpty(values.anstallningsForm) && 'anstallningsForm',
      values.inkomstUppgift?.harUppdateratLon && 'inkomstUppgift',
      values.inkomstUppgift?.harUppdateratLon && 'foretagNamn',
      values.isAvbrytaArbetssokande === 'false' && 'avbrottStartDatum',
      values.isAvbrytaArbetssokande === 'false' && 'avbrottsOrsak',
    ],
    mergedData
  )

  return formData
}

export default getFormData
