import React, { FC } from 'react'
import { Box, Link } from '@mui/material'
import { useSelector } from '@local/src/Store/storeContext'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import { IAktiveratAgeInformation } from '../NextStepAge.types'
import { utbetaldaDagar as utbetaldaDagarSelector } from '../../Age.selectors'

import { AgeStep, AgeStepsIconMap } from './AgeStep'

export const AktiveratAgeInformation: FC<IAktiveratAgeInformation> = ({
  content,
}) => {
  const utbetaldaDagar = useSelector(utbetaldaDagarSelector)

  return (
    <AgeStep
      header={content.aktiveratAgeHeading}
      icon={AgeStepsIconMap.payments}
      testSelector="aktiverat-age-information"
    >
      <>
        <HTMLMapper
          body={content.aktiveratAgeText}
          replaceValues={{ '{utbetaldaDagar}': utbetaldaDagar }}
        />

        <Box mt={2} mb={4}>
          <Link href={content.hanteraAgeLink.url}>
            {content.hanteraAgeLink.text}
          </Link>
        </Box>
      </>
    </AgeStep>
  )
}
