import React, { useEffect } from 'react'
import RaknesnurraV2 from '@local/Raknesnurra/Raknesnurra.container'
import {
  AppDispatch,
  useDispatch,
  useSelector,
} from '@local/Store/storeContext'
import { ageRakneparametrarLoaded as ageRakneparametrarLoadedSelector } from '@local/Raknesnurra/Raknesnurra.selectors'
import { getAgeRakneparametrar } from '@local/Raknesnurra/Raknesnurra.actions'
import { useEpiContent } from '@trr/app-shell-data'

import { IContent } from '../App.types'

const RaknesnurraPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const content = useEpiContent<IContent>()
  const ageRakneparametrarLoaded = useSelector(ageRakneparametrarLoadedSelector)
  const contentLoading = !('urlKey' in content)

  useEffect(() => {
    void dispatch(getAgeRakneparametrar())
  }, [dispatch])

  return !contentLoading && ageRakneparametrarLoaded ? (
    <RaknesnurraV2 content={content} />
  ) : null
}

export default RaknesnurraPage
