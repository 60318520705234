import { connect } from 'react-redux'
import { RootState } from '@local/Store/configureStore'
import { AgeContext } from '@local/Store/storeContext'
import { withRouter } from 'react-router-dom'

import { getAge, closeConfirmation } from '../Age.actions'
import {
  ageLoaded,
  confirmSubmissionSuccess,
  klientHandelserAvailability,
  sortedAgeHandelser,
  skattesats,
  arendeUpdated,
  formError,
  isPending,
} from '../Age.selectors'

import AgeHandelser from './AgeHandelser'

const connectedComponent = connect(
  // I cannot figure out how to type the ownProps to satisify the component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: RootState, ownProps: any) => ({
    ageLoaded: ageLoaded(state),
    confirmSubmissionSuccess: confirmSubmissionSuccess(state),
    contentLoading: false,
    klientHandelserAvailability: klientHandelserAvailability(state),
    skattesats: skattesats(state),
    sortedAgeHandelser: sortedAgeHandelser(ownProps),
    arendeUpdated: arendeUpdated(state),
    formError: formError(state),
    isPending: isPending(state),
  }),
  { getAge, closeConfirmation },
  null,
  { context: AgeContext }
)(AgeHandelser)

export default withRouter(connectedComponent)
