import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isNil } from 'ramda'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  files: Yup.mixed().test(
    'files',
    'Du måste bifoga lönegarantibeslut',
    (files: IUniqueFile[]) => !isNil(files[0])
  ),
})

export default validationSchema
