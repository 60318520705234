import {
  FileListError,
  FileValidation,
  IFileUploadContent,
  ValidFileExtentions,
} from '@local/Components/FileUpload/FileUpload.types'

const validFileExtensions = [
  'doc',
  'docx',
  'gif',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'rtf',
  'txt',
  'xls',
  'xlsx',
]

const isValidSize = (file: File, fileSizeLimit: number): boolean => {
  const size = file.size

  const sizeInKb = Math.floor(size / 1024)

  return sizeInKb <= fileSizeLimit
}

const isValidFileExtension = (file: File): boolean => {
  const ext = file.name.split('.').pop()
  return validFileExtensions.includes(ext.toLowerCase())
}

const fileSizeLimitInKb = 5 * 1024 //5 MB

const createFileListError = (
  message: string,
  fileErrors: FileValidation[],
  validFileExtentions: readonly ValidFileExtentions[],
  fileSizeLimit: number
) => new FileListError(message, fileErrors, validFileExtentions, fileSizeLimit)

const fileUploadContent = (
  buttonText: string,
  informationText: string
): IFileUploadContent => ({
  uploadButtonText: buttonText,
  heading: '',
  fileUploadError: 'Filen kunde inte laddas upp, vänligen försök igen',
  fileUploadCannotReadFileError:
    'Filen kunde inte laddas upp, vänligen försök igen',
  fileDeletedError: 'Filen kunde inte tas bort',
  fileSizeLimitExceeded: 'Filens storlek får inte överskrida 5 MB',
  fileExtensionError:
    'Ogiltigt format. Endast filer av följande format är tillåtna: doc, docx, gif, jpeg, jpg, pdf, png, ppt, pptx, rtf, txt, xls, xlsx',
  description: informationText,
  tooltip: '',
})

export {
  validFileExtensions,
  isValidSize,
  fileSizeLimitInKb,
  isValidFileExtension,
  createFileListError,
  fileUploadContent,
}
