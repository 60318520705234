import { FormikErrors, FormikTouched, FormikValues } from 'formik'
import { isNil } from 'ramda'

export const generateErrorMessage = ({
  selector,
  errorMsg,
  touched,
}: {
  /** The elements name attribute */
  selector: string
  /** The error provided from Formik */
  errorMsg: string | FormikErrors<FormikValues>
  /** The touched object provided by Formik */
  touched: FormikTouched<FormikValues>
}): string => (touched[selector] && !isNil(errorMsg) ? String(errorMsg) : '')
