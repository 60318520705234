import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'

import {
  intygaArbetssokandeAktivitetsstod,
  setPending,
} from '../../Age.actions'

import IntygaArbetssokandeAktivitetsstod from './IntygaArbetssokandeAktivitetsstod'

const connectedComponent = connect(
  () => ({}),
  { intygaArbetssokandeAktivitetsstod, setPending },
  null,
  {
    context: AgeContext,
  }
)(IntygaArbetssokandeAktivitetsstod)

export default connectedComponent
