import * as Yup from 'yup'
import { trrFormat } from '@local/src/Utils/Helpers/formatDate'
import { addBusinessDays } from 'date-fns'

import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'
import { IMinaInkomstUppgifterFormContent } from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm.types'
import { IInkomstuppgifter } from '../../MyAge/MyAge.types'

import { IForsakraArbetssokandeContent } from './ForsakraArbetssokande.types'

export interface IValidationSchema {
  content: IForsakraArbetssokandeContent & IMinaInkomstUppgifterFormContent
  inkomstUppgift?: IInkomstuppgifter
  startDatum: string | Date
}

const validationSchema = ({
  content,
  inkomstUppgift,
  startDatum,
}: IValidationSchema) =>
  Yup.object().shape({
    inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift.harUppdateratLon),
    arbetssokandeStartDatum: Yup.date()
      .nullable()
      .typeError('Du behöver ange ett giltigt datum')
      .min(
        trrFormat(startDatum),
        'Du har angett ett datum som ligger före AGE start'
      )
      .max(new Date(), 'Du har angett ett datum som ligger i framtiden')

      .when('isInskrivenSomArbetssokande', {
        is: 'true',
        then: (value: Yup.ObjectSchema) =>
          value.required('Du behöver välja ett datum'),
      }),
    avbrottStartDatum: Yup.date()
      .typeError('Du behöver välja ett datum')
      .when(
        ['arbetssokandeStartDatum', 'isAvbrytaArbetssokande'],
        (
          arbetssokandeStartDatum: Date,
          isAvbrytaArbetssokande: string,
          schema: Yup.DateSchema
        ) => {
          if (isAvbrytaArbetssokande === 'true') {
            const addedDays = addBusinessDays(
              new Date(arbetssokandeStartDatum),
              30
            )
            return schema.max(
              addedDays,
              'Du har angett ett datum som ligger längre fram än 30 dagar från AGE start'
            )
          }
          return schema.nullable()
        }
      ),
    isInskrivenSomArbetssokande: Yup.string().test(
      'isInskrivenSomArbetssokande',
      content.inteInskrivenSomArbetssokandeText ??
        'För att få AGE måste du vara inskriven hos Arbetsförmedlingen',
      (value) => value === 'true'
    ),
    avbrottsOrsak: Yup.mixed().when('isAvbrytaArbetssokande', {
      is: 'true',
      then: (value: Yup.MixedSchema) =>
        value.required('Du behöver välja en avbrottsorsak'),
    }),
    anstallningsForm: Yup.string()
      .nullable()
      .when(['avbrottsOrsak', 'isAvbrytaArbetssokande'], {
        is: (orsak, isAvbrytaArbetssokande) =>
          isAvbrytaArbetssokande === 'true' && orsak === '2',
        then: (value: Yup.StringSchema) =>
          value.required('Du behöver ange en anställningsform'),
      }),
    forsakran: Yup.bool().test(
      'forsakran',
      'Du måste försäkra att de uppgifter du lämnat är sanningsenliga',
      (value: boolean) => value === true
    ),
  })

export default validationSchema
