import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import React from 'react'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import TrrLogo from '@local/src/Age/SkrivUtBeslut/TrrLogo'

import {
  ISkrivUtBeslutContent,
  IDataSkrivUtBeslut,
} from './SkrivUtBeslut.types'

interface IPeriod {
  startDag: number
  slutDag: number
  dagPenning: number
}

interface Props {
  content: ISkrivUtBeslutContent
  skrivUtBeslut: IDataSkrivUtBeslut
}

const SkrivUtBeslutPdf = ({ content, skrivUtBeslut }: Props) => {
  const styles = StyleSheet.create({
    page: {
      padding: '30 32 0',
      width: '100%',
      fontFamily: 'Helvetica',
    },
    city: {
      width: '100%',
      textAlign: 'right',
      paddingBottom: 40,
      fontSize: 11,
    },
    heading: {
      fontSize: 14,
      paddingBottom: 10,
      fontWeight: 'bold',
      fontFamily: 'Helvetica-Bold',
    },
    section: {
      paddingBottom: 20,
      fontSize: 12,
    },
  })

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <TrrLogo />

          <Text style={styles.city}>
            {content.stad}, {trrFormat()}
          </Text>
          <Text style={styles.heading}>
            {content.header} {skrivUtBeslut.firstName} {skrivUtBeslut.lastName},{' '}
            {skrivUtBeslut.socialSecurityNumber}{' '}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>{content.beslutText}</Text>
          <Text>
            {content.baseradPaLonText.replace(
              '{totalLon}',
              formatCurrencySEK(skrivUtBeslut.totalLon)
            )}
          </Text>
        </View>
        <View style={styles.section}>
          <Text>
            {content.startDatumText} {trrFormat(skrivUtBeslut.startDatum)}
          </Text>
          <Text>
            {content.beviljadeDagarText} {skrivUtBeslut.maxErsattningsDagar}
          </Text>
        </View>
        <View style={styles.section}>
          {skrivUtBeslut.agePerioderSkrivUt.map((period: IPeriod) => (
            <Text key={period.startDag}>
              {content.ersattningsBeslutText.replace(
                '{period}',
                `${period.startDag}-${period.slutDag}`
              ) +
                ' ' +
                formatCurrencySEK(period.dagPenning)}
            </Text>
          ))}
        </View>
        <View style={styles.section}>
          <Text>{content.informationOmBeslutText}</Text>
        </View>
        <View style={styles.section}>
          <Text>{content.halsningarText}</Text>
          <Text>{content.trygghetsradetText}</Text>
          <Text>{content.ageSektionenText}</Text>
        </View>
      </Page>
    </Document>
  )
}

export default SkrivUtBeslutPdf
