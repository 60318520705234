import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import sv from 'date-fns/locale/sv'

type trrDate = (date?: Date | string) => Date

type baseTrrFormat = (formatStr: string) => (date?: Date | string) => string

const stockholmTimeZone = 'Europe/Stockholm'

export const trrDate: trrDate = (date) =>
  utcToZonedTime(date ? date : new Date(), stockholmTimeZone)

export const trrDateUTC: trrDate = (date: Date) =>
  zonedTimeToUtc(date, stockholmTimeZone)

export const baseTrrFormat: baseTrrFormat =
  (formatStr: string) =>
  (date: Date | string = new Date()) =>
    format(trrDate(date), formatStr, {
      timeZone: stockholmTimeZone,
      locale: sv,
    })

export const trrFormat = baseTrrFormat('yyyy-MM-dd')
export const trrFormatOccasionDate = baseTrrFormat('d MMM yyyy')
export const trrFormatTime = baseTrrFormat('H:mm')
export const trrFormatMeetingDateSummary = baseTrrFormat('EEEE d MMMM yyyy')
export const trrFormatMeetingTimeSummary = baseTrrFormat('HH:mm')
