import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'

import { rapporteraAteroppnatArende, setPending } from '../../Age.actions'

import AteroppnatArende from './AteroppnatArende'

const connectedComponent = connect(
  () => ({}),
  { rapporteraAteroppnatArende, setPending },
  null,
  { context: AgeContext }
)(AteroppnatArende)

export default connectedComponent
