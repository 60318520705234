import * as React from 'react'
import getConfig from '@local/Utils/getConfig'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid2 as Grid, Link, Typography, useTheme } from '@mui/material'

interface IEpiFile {
  relLink: string
  label: string
  testSelector?: string
}

const EpiFile: React.FunctionComponent<React.PropsWithChildren<IEpiFile>> = ({
  relLink,
  label,
  testSelector,
}) => {
  const theme = useTheme()
  const url = `${getConfig().MEDIA_URL}${relLink}`

  return (
    <Grid container py={1}>
      <Grid size={{ xs: 'auto' }} mr={0.5}>
        <OpenInNewIcon style={{ color: theme.palette.primary.main }} />
      </Grid>
      <Grid size={{ xs: 10 }}>
        <Link
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          data-testid={testSelector}
          sx={{
            textDecoration: 'none',
            color: 'unset',
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
            },
          }}
        >
          <Typography variant="body1" color={theme.palette.primary.main}>
            {label}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  )
}

export default EpiFile
