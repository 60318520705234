import React, { useEffect } from 'react'
import AgeHandelser from '@local/Age/AgeHandelser'
import {
  AppDispatch,
  useDispatch,
  useSelector,
} from '@local/Store/storeContext'
import { useEpiContent } from '@trr/app-shell-data'
import { cleanAge, getAge } from '@local/Age/Age.actions'
import { ageLoaded as ageLoadedSelector } from '@local/Age/Age.selectors'

import { IContent } from '../App.types'

const HandleAge: React.FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch()
  const content = useEpiContent<IContent>()
  const contentLoading = !('andraSkatteavdrag' in content)
  const ageLoaded = useSelector(ageLoadedSelector)

  useEffect(() => {
    void dispatch(getAge())
    return () => void dispatch(cleanAge())
  }, [dispatch])

  return !contentLoading && ageLoaded ? (
    <AgeHandelser content={content} />
  ) : null
}

export default HandleAge
