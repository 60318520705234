import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { sv } from 'date-fns/locale'
import React, { useState } from 'react'

interface IDatePicker {
  value: Date
  errorMessage: string
  onChange: (value: Date | string) => void

  name: string
  label: string
  customClass?: string
  disabled?: boolean
  testId?: string
  minDate?: Date
  maxDate?: Date
}

export const DatePicker = ({
  value,
  errorMessage,
  onChange,
  name,
  label,
  customClass,
  disabled,
  testId,
  minDate,
  maxDate,
}: IDatePicker) => {
  const [open, setOpen] = useState(false)
  const handleOpenDatePicker = () => setOpen(true)
  const handleCloseDatePicker = () => setOpen(false)
  const defaultTestId = testId || name

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
      <MuiDatePicker
        label={label}
        disabled={disabled}
        onChange={onChange}
        open={open}
        value={value ? value : null}
        onOpen={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        minDate={minDate}
        maxDate={maxDate}
        className={customClass}
        slotProps={{
          textField: {
            error: Boolean(errorMessage),
            helperText: errorMessage,
            fullWidth: true,
            onClick: handleOpenDatePicker,
            disabled: disabled,
            inputProps: {
              'data-testid': defaultTestId,
            },
          },
          popper: {
            id: `${defaultTestId}-datepicker`,
          },
        }}
      />
    </LocalizationProvider>
  )
}
