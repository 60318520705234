import {
  number as yupNumber,
  object as yupObject,
  string as yupString,
  date as yupDate,
  Schema,
} from 'yup'

export const inkomstUppgiftSchema = (
  harUppdateratLon?: boolean
): Schema<unknown> => {
  const notEmptyFieldLabel = 'Fältet får inte vara tomt'
  const invalidDateLabel = 'Du behöver ange ett giltigt datum'

  return harUppdateratLon
    ? yupObject().shape({})
    : yupObject().shape({
        manadsLon: yupNumber()
          .transform((value: number) => (isNaN(value) ? undefined : value))
          .required(notEmptyFieldLabel)
          .moreThan(0, 'Månadslön måste överstiga 0 kr'),
        provision: yupString().required(notEmptyFieldLabel),
        lonerevisionsDatum: yupDate()
          .required(notEmptyFieldLabel)
          .typeError(invalidDateLabel),
        jour: yupString().required(notEmptyFieldLabel),
      })
}
