import * as React from 'react'
import { useFormik } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { trrDate } from '@local/Utils/Helpers/formatDate'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import Spinner from '@local/Components/Spinner'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useEffect } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import EpiFile from '@local/src/Components/EpiFile'

import FormSubmit from '../../../Components/FormSubmit'
import MinaInkomstUppgifterForm from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm'
import { IInkomstuppgifter } from '../../MyAge/MyAge.types'

import {
  IEkonomisktForetagsstod,
  IEkonomisktForetagsstodFormValues,
} from './EkonomisktForetagsstod.types'
import validationSchema from './EkonomisktForetagsstod.schema'
import { getFormData } from './EkonomisktForetagsstod.helper'

const getInitialValues = (
  inkomstUppgift: IInkomstuppgifter
): IEkonomisktForetagsstodFormValues => ({
  organisationsNummer: '',
  foretagsnamn: '',
  startDatum: null,
  agarandel: null,
  harVaritAktivtArbetssokande: '',
  files: [],
  fskattesedel: [],
  arbetssokandeIntygTyp: '',
  inkomstUppgift,
})

export const EkonomisktForetagsstod = ({
  formName,
  content,
  handleCancel,
  foretagNamn,
  isExpanded,
  isPending,
  inkomstUppgift,
  setPending,
  postRapporteraEkonomisktForetagsstod,
}: IEkonomisktForetagsstod) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values)

    postRapporteraEkonomisktForetagsstod(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const initialValues = getInitialValues(inkomstUppgift)

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema: validationSchema({
      content,
      inkomstUppgift,
    }),
  })
  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const { handleChange, setFieldValue } = formik

  const handleAktivArbetssokandeOptionsChange = React.useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      handleChange(e)
      void setFieldValue('files', [])
    },
    [handleChange, setFieldValue]
  )

  //Make sure that the user can only input 3 digits and 1 decimal point
  const checkCorrectFormatAgarandel = (value: string) => {
    const regex = /^(\d{1,3}([.,]\d*)?)?$/
    return regex.test(value)
  }

  const handleAgarandelChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      if (checkCorrectFormatAgarandel(value)) {
        setFieldValue('agarandel', value).catch((error) => {
          console.error(error)
        })
      }
    },
    [setFieldValue]
  )

  if (!content) return <Spinner size={24} />

  const fileUpload = (text: string) => {
    const uploadContent = fileUploadContent(text, content.attachmentButtonText)

    return (
      <FileUpload
        isLoadingUpload={false}
        content={uploadContent}
        inputName={'files'}
        files={formik.values.files}
        setFieldValue={setFieldValue}
        errorMessage={
          formik.touched.files && formik.errors.files
            ? String(formik.errors.files)
            : ''
        }
      />
    )
  }

  const renderArbetssokandeIntygTyp = (type: string) => {
    switch (type) {
      case 'intyg-fran-arbetsformedling':
        return <div>{fileUpload('Intyg från arbetsförmedling. ')}</div>
      case 'specifikation-om-ersattning':
        return <div>{fileUpload('Specifikation om ersättning. ')}</div>
      case 'beslut-om-anvisning':
        return <div>{fileUpload('Beslut om anvisning. ')}</div>
      default:
        return null
    }
  }

  const renderRadioButtons = (display: boolean) =>
    display ? (
      <div data-testid="harVaritAktivtArbetssokande-buttons">
        <Typography variant="body1" mb={1}>
          <HTMLMapper body={content.verifyArbetssokandeText} />
        </Typography>

        <RadioGroup
          name="arbetssokandeIntygTyp"
          value={formik.values.arbetssokandeIntygTyp}
        >
          <FormControlLabel
            label="Jag har lämnat samtycke till a-kassan att TRR får ta del av godkända dagar"
            value="a-kassa-samtycke"
            data-testid="a-kassa-samtycke"
            onChange={handleAktivArbetssokandeOptionsChange}
            control={<Radio />}
          />

          <FormControlLabel
            control={<Radio />}
            label="Intyg från arbetsförmedling"
            onChange={handleAktivArbetssokandeOptionsChange}
            value="intyg-fran-arbetsformedling"
            data-testid="intyg-fran-arbetsformedling"
          />

          <FormControlLabel
            control={<Radio />}
            label="Beslut om anvisning (aktivitetsstöd)"
            value="beslut-om-anvisning"
            data-testid="beslut-om-anvisning"
            onChange={handleAktivArbetssokandeOptionsChange}
          />

          <FormControlLabel
            control={<Radio />}
            label="Specifikation från a-kassan, ej samtycke"
            onChange={handleAktivArbetssokandeOptionsChange}
            value="specifikation-om-ersattning"
            data-testid="specifikation-om-ersattning"
          />

          <FormHelperText
            error={Boolean(
              formik.touched.arbetssokandeIntygTyp &&
                formik.errors.arbetssokandeIntygTyp
            )}
          >
            {formik.errors.arbetssokandeIntygTyp}
          </FormHelperText>
        </RadioGroup>

        {renderArbetssokandeIntygTyp(formik.values.arbetssokandeIntygTyp)}
      </div>
    ) : null

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box paddingTop={1}>
        <HTMLMapper body={content.informationText} />
      </Box>

      {!inkomstUppgift.harUppdateratLon && (
        <MinaInkomstUppgifterForm
          handleChange={handleChange}
          values={formik.values.inkomstUppgift}
          handleChangeDate={customSetFieldValue(
            setFieldValue,
            'inkomstUppgift.lonerevisionsDatum'
          )}
          content={content.inkomstUppgift}
          foretagNamn={foretagNamn}
          errors={formik.errors.inkomstUppgift}
          handleBlur={formik.handleBlur}
        />
      )}

      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="h6" pt={4} pb={4}>
            {content.foretagsUppgifterHeader}
          </Typography>
          <Typography variant="body1">
            {content.foretagsUppgifterMainBody}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            error={Boolean(
              formik.touched.organisationsNummer &&
                formik.errors.organisationsNummer
            )}
            helperText={
              formik.touched.organisationsNummer &&
              formik.errors.organisationsNummer
            }
            label={content.organisationNumberInputFieldHeader}
            onChange={handleChange}
            name="organisationsNummer"
            onBlur={formik.handleBlur}
            value={formik.values.organisationsNummer}
          />
        </Grid>
        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            error={Boolean(
              formik.touched.foretagsnamn && formik.errors.foretagsnamn
            )}
            helperText={
              formik.touched.foretagsnamn && formik.errors.foretagsnamn
            }
            label={content.foretagsnamnInputFieldHeader}
            onChange={handleChange}
            name="foretagsnamn"
            onBlur={formik.handleBlur}
            value={formik.values.foretagsnamn}
          />
        </Grid>
        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            fullWidth
            error={Boolean(formik.touched.agarandel && formik.errors.agarandel)}
            helperText={formik.touched.agarandel && formik.errors.agarandel}
            label={content.agarandelInputFieldHeader}
            onChange={handleAgarandelChange}
            name="agarandel"
            onBlur={formik.handleBlur}
            value={formik.values.agarandel}
            type="number"
          />
        </Grid>

        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePicker
            label={content.startDatumInputFieldHeader}
            value={formik.values.startDatum}
            maxDate={trrDate()}
            name="startDatum"
            errorMessage={
              formik.touched.startDatum && formik.errors.startDatum !== ''
                ? (formik.errors.startDatum as string)
                : ''
            }
            onChange={customSetFieldValue(setFieldValue, 'startDatum')}
          />
        </Grid>
        <Grid size={{ xs: 12 }} mt={4}>
          <FileUpload
            isLoadingUpload={false}
            content={fileUploadContent(
              'Bifoga',
              'Bifoga registreringsutdrag F-skattsedel'
            )}
            inputName={'fskattesedel'}
            files={formik.values.fskattesedel}
            setFieldValue={setFieldValue}
            errorMessage={
              formik.touched.fskattesedel && formik.errors.fskattesedel
                ? String(formik.errors.fskattesedel)
                : ''
            }
            testSelector="fskattesedel"
          />
        </Grid>

        <Grid size={{ xs: 12 }} mt={4}>
          <EpiFile
            relLink={content.fskattesedelExampleFile}
            label={content.fskattesedelExampleFileLabel}
          />
        </Grid>
        <Grid size={{ xs: 6 }} />
        <Grid size={{ xs: 12 }} mt={4}>
          <Typography variant="body1">
            Har du varit aktivt arbetssökande eller deltagit i en aktivitet via
            Arbetsförmedlingen innan du startade ditt företag?
          </Typography>

          <RadioGroup
            name="harVaritAktivtArbetssokande"
            value={formik.values.harVaritAktivtArbetssokande}
          >
            <FormControlLabel
              label="Ja"
              value="true"
              data-testid="harVaritAktivtArbetssokande"
              onChange={handleChange}
              control={<Radio />}
            />

            <FormControlLabel
              label="Nej"
              value="false"
              data-testid="notVaritAktivtArbetssokande"
              onChange={handleChange}
              control={<Radio />}
            />

            <FormHelperText
              error={Boolean(
                formik.touched.harVaritAktivtArbetssokande &&
                  formik.errors.harVaritAktivtArbetssokande
              )}
            >
              {formik.errors.harVaritAktivtArbetssokande}
            </FormHelperText>
          </RadioGroup>
        </Grid>
        <Grid size={{ xs: 12 }} mt={4}>
          {renderRadioButtons(
            formik.values.harVaritAktivtArbetssokande === 'true'
          )}
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default EkonomisktForetagsstod
