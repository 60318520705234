import { omit } from 'ramda'
import { isBefore } from 'date-fns'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import { IAvgangsvederlagFormValues } from './Avgangsvederlag.types'

export const getFormData = (
  values: IAvgangsvederlagFormValues,
  startDatum: string
) => {
  const formData = new FormData()
  const file = values.files[0]
  const filename = file?.name ?? ''
  const json = JSON.stringify(omit(['files'], values))

  if (isBefore(new Date(values.vederlagsDatum ?? ''), new Date(startDatum))) {
    const trimmedFileName = trimPathInformation(filename)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }
  formData.append('data', json)

  return formData
}
