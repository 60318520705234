import { Typography } from '@mui/material'
import React from 'react'

import { SaknarAgeRattighet } from './SaknarAgeRattighet'
import { ISaknarAgeRattigheter } from './SaknarAgeRattigheter.types'

export const SaknarAgeRattigheter = ({
  hasTillatenLon,
  is40EllerAldre,
  isYngreAnAgeMaxAlder,
  ejTillatenLonInformation,
  yngreAn40Information,
  aldreAn65Information,
  ejRattTillAgeInformation,
}: ISaknarAgeRattigheter): JSX.Element => (
  <div data-testid="saknar-age-rattigheter">
    <SaknarAgeRattighet
      saknarRattighet={!is40EllerAldre}
      information={yngreAn40Information}
    />
    <SaknarAgeRattighet
      saknarRattighet={!isYngreAnAgeMaxAlder}
      information={aldreAn65Information}
    />
    <SaknarAgeRattighet
      saknarRattighet={!hasTillatenLon}
      information={ejTillatenLonInformation}
    />
    <Typography variant="body2">{ejRattTillAgeInformation}</Typography>
  </div>
)
