import * as React from 'react'
import { Box, Typography } from '@mui/material'

export interface IArendeNummer {
  title?: string
  arendeNummer?: string
}

const ArendeNummer: React.FunctionComponent<
  React.PropsWithChildren<IArendeNummer>
> = ({ title, arendeNummer }) =>
  title && arendeNummer ? (
    <Box
      sx={(theme) => ({
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.surface.purple,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
      })}
    >
      <Typography variant="h6">{`${title}: ${arendeNummer}`}</Typography>
    </Box>
  ) : null

export default ArendeNummer
