import * as Yup from 'yup'

const validationSchema = Yup.lazy(() =>
  Yup.object()
    .nullable()
    .shape({
      arbetssokandeStartDatum: Yup.date()
        .nullable()
        .required('Du behöver fylla i ett datum')
        .typeError('Du behöver ange ett giltigt datum'),
    })
)

export default validationSchema
