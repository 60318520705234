import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { omit } from 'ramda'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import FormErrorMessage from '@local/src/Components/FormErrorMessage'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IArbetssokandeAvbrott,
  IArbetssokandeAvbrottFormValues,
  IArbetssokandeAvbrottSubmitValues,
} from './ArbetssokandeAvbrott.types'
import validationSchema from './ArbetssokandeAvbrott.schema'

const initialValues: IArbetssokandeAvbrottFormValues = {
  avbrottsOrsak: '2',
  avbrottsDatum: null,
  anstallningsForm: '',
}

const ArbetssokandeAvbrott: FC<IArbetssokandeAvbrott> = ({
  formName,
  content,
  setPending,
  rapporteraArbetssokandeAvbrott,
  handleCancel,
  isExpanded,
  isPending,
}) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = (values: IArbetssokandeAvbrottFormValues) => {
    const filteredData = omit(['anstallningsForm'], values)
    const anstallning = {
      anstallningsForm: values?.anstallningsForm,
    }
    const sendData: IArbetssokandeAvbrottSubmitValues = {
      ...filteredData,
      ...(values.avbrottsOrsak === '2' && anstallning),
    }

    rapporteraArbetssokandeAvbrott(sendData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema,
  })

  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const handleSelectChange = (event: SelectChangeEvent) => {
    void formik.setFieldValue('anstallningsForm', event.target.value)
  }

  const anstallningsFormError = generateErrorMessage({
    selector: 'anstallningsForm',
    errorMsg: formik.errors.anstallningsForm ?? '',
    touched: formik.touched,
  })

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box paddingTop={1} paddingBottom={2}>
        <HTMLMapper body={content.informationText} />
      </Box>

      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 6}}>
          <DatePicker
            onChange={customSetFieldValue(
              formik.setFieldValue,
              'avbrottsDatum'
            )}
            errorMessage={generateErrorMessage({
              selector: 'avbrottsDatum',
              errorMsg: formik.errors.avbrottsDatum ?? '',
              touched: formik.touched,
            })}
            label={content.dateInputFieldHeader}
            value={formik.values.avbrottsDatum}
            name="avbrottsDatum"
          />
        </Grid>
      </Grid>

      <Box py={2}>
        <RadioGroup defaultValue="2" name="avbrottsOrsak">
          <Typography variant="body1">
            {content.avbrottsOrsakBulletPointHeader}
          </Typography>

          <FormControlLabel
            value="2"
            control={<Radio />}
            label="Ny anställning"
            onChange={formik.handleChange}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="Pension"
            onChange={formik.handleChange}
          />
          <FormControlLabel
            value="7"
            control={<Radio />}
            label="Annat avbrott"
            onChange={formik.handleChange}
          />
        </RadioGroup>
      </Box>

      {formik.values.avbrottsOrsak === '2' && (
        <Grid container>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormControl fullWidth>
              <InputLabel id="anstallningsForm-label">
                Anställningsform:
              </InputLabel>
              <Select
                labelId="anstallningsForm-label"
                name="anstallningsForm"
                label="Anställningsform:"
                value={formik.values.anstallningsForm}
                onChange={handleSelectChange}
              >
                <MenuItem value="1">Tillsvidare</MenuItem>
                <MenuItem value="2">Visstid</MenuItem>
                <MenuItem value="3">Provanställning</MenuItem>
                <MenuItem value="4">Projektanställning</MenuItem>
              </Select>

              {anstallningsFormError?.length > 0 && (
                <FormErrorMessage>{anstallningsFormError}</FormErrorMessage>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default ArbetssokandeAvbrott
