import { omit } from 'ramda'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import {
  IIntygaArbetssokandeContent,
  IIntygaArbetssokandeFormValues,
} from './IntygaArbetssokande.types'

export const getLabels = (
  arbetssokandeIntygTyp: string,
  content: IIntygaArbetssokandeContent
): {
  informationText: string
  attachmentLabel: string
  exampleFile: string
  exampleFileLabel: string
} => {
  if (arbetssokandeIntygTyp === 'specifikation-om-ersattning') {
    return {
      informationText: content.informationTextAkassa,
      attachmentLabel: content.attachmentLabelAkassa,
      exampleFile: content.akassaExampleFile,
      exampleFileLabel: content.akassaExampleFileLabel,
    }
  }
  return {
    informationText: content.informationTextArbetsformedling,
    attachmentLabel: content.attachmentLabelArbetsformedling,
    exampleFile: content.arbetsformedlingExampleFile,
    exampleFileLabel: content.arbetsformedlingExampleFileLabel,
  }
}

export const getFormData = (values: IIntygaArbetssokandeFormValues) => {
  const formData = new FormData()

  const json = JSON.stringify(omit(['files'], values))
  formData.append('data', json)
  const file = values.files[0]

  if (file) {
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  return formData
}
