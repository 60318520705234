import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from '@local/Store/storeContext'
import { withHooks } from '@local/Utils/Hooks'
import {
  ageLoaded,
  arendeUpdated,
  confirmSubmissionSuccess,
  enableAkassaSamtycke,
  enableForsakraArbetssokande,
  enableRapporteraEkonomisktForetagsstod,
  formError,
  harAgeEjStartad,
  harAktiveratAgeEjAvslutad,
  harAvslutadAge,
  harInteAkassa,
  isPending,
  lockAkassaSamtycke,
  lockForsakraArbetssokande,
  lockRapporteraEkonomisktForetagsstod,
  startDatum,
} from '@local/Age/Age.selectors'
import { showSystemFeedback } from '@local/src/Components/SystemFeedback/SystemFeedback.actions'

import { getAge, setPending } from '../Age.actions'

import NextStep from './NextStepAge'

const NextStepActions = () => {
  const dispatch = useDispatch()

  return bindActionCreators(
    {
      getAge,
      setPending,
      showSystemFeedback,
    },
    dispatch
  )
}

const NextStepSelectors = () => ({
  startDatum: useSelector(startDatum),
  ageLoaded: useSelector(ageLoaded),
  enableAkassaSamtycke: useSelector(enableAkassaSamtycke),
  lockAkassaSamtycke: useSelector(lockAkassaSamtycke),
  confirmSubmissionSuccess: useSelector(confirmSubmissionSuccess),
  arendeUpdated: useSelector(arendeUpdated),
  formError: useSelector(formError),
  harInteAkassa: useSelector(harInteAkassa),
  isPending: useSelector(isPending),
  harAktiveratAgeEjAvslutad: useSelector(harAktiveratAgeEjAvslutad),
  harAvslutadAge: useSelector(harAvslutadAge),
  harAgeEjStartad: useSelector(harAgeEjStartad),
  enableForsakraArbetssokande: useSelector(enableForsakraArbetssokande),
  enableRapporteraEkonomisktForetagsstod: useSelector(
    enableRapporteraEkonomisktForetagsstod
  ),
  lockForsakraArbetssokande: useSelector(lockForsakraArbetssokande),
  lockRapporteraEkonomisktForetagsstod: useSelector(
    lockRapporteraEkonomisktForetagsstod
  ),
})

export default withHooks({ NextStepActions, NextStepSelectors })(NextStep)
