import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'

import { lonegarantibeslut, setPending } from '../../Age.actions'

import Lonegaranti from './Lonegaranti'

const connectedComponent = connect(
  () => ({}),
  { lonegarantibeslut, setPending },
  null,
  { context: AgeContext }
)(Lonegaranti)

export default connectedComponent
