import { intervalToDuration } from 'date-fns'

import { convertToDateWithoutTime } from '../Raknesnurra.helper'

import { IDagpenningAkassaPeriod } from './BeraknaAge.types'
import {
  formatDate,
  getAgeEnd,
  getRetirementAge,
  getAgeStart,
} from './BeraknaAge.helpers'

export const getIs40EllerAldre = ({
  fodelseDatum,
  sistaAnstallningsdag,
}: {
  fodelseDatum: Date
  sistaAnstallningsdag: Date
}): boolean => {
  const duration = intervalToDuration({
    start: fodelseDatum,
    end: sistaAnstallningsdag,
  })

  const years = duration?.years ?? 0
  return years >= 40
}

export const getIsYoungerThanRetirementAge = ({
  fodelseDatum,
  sistaAnstallningsdag,
}: {
  fodelseDatum: Date
  sistaAnstallningsdag: Date
}): boolean => {
  const duration = intervalToDuration({
    start: fodelseDatum,
    end: sistaAnstallningsdag,
  })

  const retirementAge = getRetirementAge(fodelseDatum)
  const ageStart = getAgeStart(sistaAnstallningsdag)
  const ageEnd = getAgeEnd(fodelseDatum)
  const hasAge = formatDate(ageEnd) >= formatDate(ageStart)
  const years = duration.years ?? 0

  return years < retirementAge && hasAge
}

export const getHasTillatenLon = ({
  manadslon,
  sistaAnstallningsdag,
  mojligaDagpenningar,
}: {
  manadslon: number
  sistaAnstallningsdag: Date
  mojligaDagpenningar: IDagpenningAkassaPeriod[]
}): boolean => {
  const ageStart = convertToDateWithoutTime(sistaAnstallningsdag)
  return mojligaDagpenningar.some(
    (mojligDagpenning) =>
      convertToDateWithoutTime(mojligDagpenning.giltigtFrom) <= ageStart &&
      convertToDateWithoutTime(mojligDagpenning.giltigtTom) >= ageStart &&
      mojligDagpenning.minLonForAgePeriod <= manadslon
  )
}
