import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import { IIntygaAktivitetstodFormValues } from './IntygaAktivitetsstod.types'

export const getFormData = (values: IIntygaAktivitetstodFormValues) => {
  const json = JSON.stringify({
    aktivitetsstod: {
      startDatum: values.startDatum,
      slutDatum: values.slutDatum,
    },
    ...(values.inkomstUppgift?.harUppdateratLon
      ? null
      : { inkomstUppgift: values.inkomstUppgift }),
  })

  const formData = new FormData()
  formData.append('data', json)

  const file = values.files[0]

  if (file) {
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  return formData
}
