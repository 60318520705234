/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createActions } from 'redux-actions'
import { get } from '@local/Utils/Network'

import { IAgeRakneparametrar } from './Raknesnurra.types'

export const {
  getAgeRakneparametrarInitiate,
  getAgeRakneparametrarSuccess,
  getAgeRakneparametrarFailure,
} = createActions({
  getAgeRakneparametrarInitiate: () => null,
  getAgeRakneparametrarSuccess: (data: IAgeRakneparametrar) => data,
  getAgeRakneparametrarFailure: () => ({}),
})

export const getAgeRakneparametrar = () =>
  get({
    fulfilled: getAgeRakneparametrarSuccess,
    initiated: getAgeRakneparametrarInitiate,
    rejected: getAgeRakneparametrarFailure,
    url: '/rakneparametrar/age',
    appendSub: false,
    isPublicWebApi: true,
    isAnonymousRequest: true,
  })
