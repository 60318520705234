import * as Yup from 'yup'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isNil } from 'ramda'

import { inkomstUppgiftSchema } from '../Common/MinaInkomstUppgifterForm'
import { IInkomstuppgifter } from '../../MyAge/MyAge.types'

export interface IValidationSchema {
  inkomstUppgift?: IInkomstuppgifter
}

const validationSchema = ({ inkomstUppgift }: IValidationSchema) =>
  Yup.object().shape({
    startDatum: Yup.date()
      .nullable()
      .required('Du måste välja ett datum')
      .typeError('Du måste ange ett giltigt datum'),
    slutDatum: Yup.date()
      .nullable()
      .required('Du måste välja ett datum')
      .typeError('Du måste ange ett giltigt datum'),
    inkomstUppgift: inkomstUppgiftSchema(inkomstUppgift.harUppdateratLon),
    files: Yup.mixed().test(
      'files',
      'Du måste välja en fil',
      (files: IUniqueFile[]) => !isNil(files[0])
    ),
  })

export default validationSchema
