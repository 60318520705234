import React, { FC } from 'react'
import { Form, FormikProps, withFormik } from 'formik'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import {
  Box,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'

import FormSubmit from '../Components/FormSubmit'
import { DatePicker } from '../Components/DatePicker'
import FormErrorMessage from '../Components/FormErrorMessage'

import raknesnurraValidationSchema from './Raknesnurra.schema'
import {
  IRaknesnurraForm,
  IRaknesnurraFormContent,
  IRaknesnurraFormValues,
} from './Raknesnurra.types'
import { Result } from './Result/Result'

const RaknesnurraForm: FC<
  IRaknesnurraForm & FormikProps<IRaknesnurraFormValues>
> = ({
  errors,
  handleChange,
  touched,
  setFieldValue,
  handleSubmit,
  values: { uppsagningsar, sistaAnstallningsdag, fodelsedatum, loneniva },
  content,
  dagPenningAkassaPerioder,
  inkomstbasbeloppPerioder,
  akasseBeloppPerioder,
  isSubmitting,
}) => {
  const currentYear = new Date().getFullYear()
  const minDate = new Date(currentYear - 5, 1, 1)
  const maxDate = new Date(currentYear + 5, 11, 31)

  const fodelsedatumError = generateErrorMessage({
    touched,
    selector: 'fodelsedatum',
    errorMsg: errors.fodelsedatum,
  })

  const lonenivaError = generateErrorMessage({
    touched,
    selector: 'loneniva',
    errorMsg: errors.loneniva,
  })

  const handleSelectChange = (event: SelectChangeEvent) => {
    void setFieldValue('uppsagningsar', event.target.value)
  }

  return (
    <Box
      px={4}
      py={4}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(0.5),
      })}
      mb={4}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h3">{content.rubrik}</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              error={Boolean(fodelsedatumError)}
              label={content.fodelsedatumLabel}
              onChange={handleChange}
              name="fodelsedatum"
              autoComplete="bday"
              helperText={fodelsedatumError}
              fullWidth
              value={fodelsedatum}
              type="text"
              inputProps={{
                inputmode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <FormControl fullWidth>
              <InputLabel id="uppsagningsar-label">
                {content.uppsagningsarLabel}
              </InputLabel>
              <Select
                labelId="uppsagningsar-label"
                name="uppsagningsar"
                value={uppsagningsar?.toString()}
                label={content.uppsagningsarLabel}
                onChange={handleSelectChange}
              >
                <MenuItem value={currentYear}>{currentYear}</MenuItem>
                <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
                <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
                <MenuItem value={currentYear - 3}>{currentYear - 3}</MenuItem>
                <MenuItem value={currentYear - 4}>{currentYear - 4}</MenuItem>
              </Select>

              {touched.uppsagningsar && errors.uppsagningsar?.length > 0 && (
                <FormErrorMessage>{errors.uppsagningsar}</FormErrorMessage>
              )}
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <DatePicker
              name="sistaAnstallningsdag"
              value={sistaAnstallningsdag}
              minDate={minDate}
              maxDate={maxDate}
              onChange={customSetFieldValue(
                setFieldValue,
                'sistaAnstallningsdag'
              )}
              label={content.sistaAnstallningsdagLabel}
              errorMessage={
                touched.sistaAnstallningsdag
                  ? (errors.sistaAnstallningsdag as string)
                  : ''
              }
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              error={Boolean(lonenivaError)}
              label={content.loneniva}
              onChange={handleChange}
              value={loneniva}
              name="loneniva"
              type="text"
              helperText={lonenivaError}
              fullWidth
              inputProps={{
                inputmode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <FormSubmit
              submitAction={handleSubmit}
              submitText={content.beraknaAgeButtonText}
            />
          </Grid>
        </Grid>
      </Form>
      <Result
        content={content}
        reRenderFlag={isSubmitting}
        dagPenningAkassaPerioder={dagPenningAkassaPerioder}
        inkomstbasbeloppPerioder={inkomstbasbeloppPerioder}
        akasseBeloppPerioder={akasseBeloppPerioder}
      />
    </Box>
  )
}

export default withFormik<IRaknesnurraForm, IRaknesnurraFormValues>({
  validateOnBlur: false,
  mapPropsToValues: () => ({
    fodelsedatum: null,
    uppsagningsar: null,
    sistaAnstallningsdag: null,
    loneniva: null,
  }),
  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(false)
  },
  validationSchema: ({ content }: { content: IRaknesnurraFormContent }) =>
    raknesnurraValidationSchema(content),
  displayName: 'RaknesnurraForm',
})(RaknesnurraForm)
