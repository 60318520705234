import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'
import { RootState } from '@local/Store/configureStore'

import { intygaArbetssokande, setPending } from '../../Age.actions'
import {
  kanIntygaArbetssokandeAkassa,
  dagarTillUtbetalning,
} from '../../Age.selectors'

import IntygaArbetssokande from './IntygaArbetssokande'

const connectedComponent = connect(
  (state: RootState) => ({
    kanIntygaArbetssokandeAkassa: kanIntygaArbetssokandeAkassa(state),
    dagarTillUtbetalning: dagarTillUtbetalning(state),
  }),
  {
    intygaArbetssokande,
    setPending,
  },
  null,
  { context: AgeContext }
)(IntygaArbetssokande)

export default connectedComponent
