import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  startDatum: Yup.date()
    .nullable()
    .required('Du måste välja ett datum')
    .typeError('Du måste ange ett giltigt datum'),
  slutDatum: Yup.date()
    .nullable()
    .required('Du måste välja ett datum')
    .typeError('Du måste ange ett giltigt datum'),
  filesArbetssokande: Yup.array().required('Du måste välja en fil'),
  filesAktivitetsstod: Yup.array().required('Du måste välja en fil'),
})

export default validationSchema
