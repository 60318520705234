import { useFormikContext } from 'formik'

import {
  convertFodelsedatumToDate,
  getRetirementAge,
} from '../BeraknaAge/BeraknaAge.helpers'
import { IRaknesnurraFormValues } from '../Raknesnurra.types'

export const useRetirementAge = () => {
  const { values } = useFormikContext<IRaknesnurraFormValues>()
  const birthdate = convertFodelsedatumToDate(
    values?.fodelsedatum?.toString() || ''
  )
  const result = getRetirementAge(birthdate)

  return result
}
