import { handleActions } from 'redux-actions'

import {
  IAkasseBeloppPeriod,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from './BeraknaAge/BeraknaAge.types'
import {
  getAgeRakneparametrarFailure,
  getAgeRakneparametrarInitiate,
  getAgeRakneparametrarSuccess,
} from './Raknesnurra.actions'
import {
  ConvertAkasseBeloppDates,
  ConvertDagPenningAkassaDates,
  ConvertInkomstbasbeloppDates,
} from './Raknesnurra.helper'

export interface IAgeRakneparametrarState {
  ageRakneparametrarLoaded: boolean
  dagPenningAkassaPerioder: IDagpenningAkassaPeriod[]
  inkomstbasbeloppPerioder: IInkomstbasbeloppPeriod[]
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
}

export const initialState: IAgeRakneparametrarState = {
  ageRakneparametrarLoaded: false,
  dagPenningAkassaPerioder: [],
  inkomstbasbeloppPerioder: [],
  akasseBeloppPerioder: [],
}

const ageRaknesnurraReducer = handleActions(
  {
    [getAgeRakneparametrarInitiate.toString()]: (state) => ({
      ...state,
      ageRakneparametrarLoaded: false,
    }),
    [getAgeRakneparametrarSuccess.toString()]: (state, action) => ({
      ...state,
      dagPenningAkassaPerioder: ConvertDagPenningAkassaDates(
        action.payload.dagPenningAkassaPerioder
      ),
      inkomstbasbeloppPerioder: ConvertInkomstbasbeloppDates(
        action.payload.inkomstbasbeloppPerioder
      ),
      akasseBeloppPerioder: ConvertAkasseBeloppDates(
        action.payload.akasseBeloppPerioder
      ),
      ageRakneparametrarLoaded: true,
    }),
    [getAgeRakneparametrarFailure.toString()]: (state) => ({
      ...state,
      ageRakneparametrarLoaded: false,
    }),
  },
  initialState
)

export default ageRaknesnurraReducer
