import { useSelector } from '../Store/storeContext'

/**
 * Use a selector to fetch data from our typed root store.
 * @param selector The selector function, sent to useSelector internally.
 */
export const useStateSelector = <TResult>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selector: (state: any) => TResult
): TResult => useSelector(selector)
