import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'
import { omit } from 'ramda'

import { IEkonomisktForetagsstodFormValues } from './EkonomisktForetagsstod.types'

export const getFormData = (values: IEkonomisktForetagsstodFormValues) => {
  const filteredData = omit(
    [
      'files',
      'fskattesedel',
      values.inkomstUppgift?.harUppdateratLon && 'inkomstUppgift',
      values.harVaritAktivtArbetssokande === 'false' && 'arbetssokandeIntygTyp',
    ],
    values
  )

  const formData = new FormData()
  const json = JSON.stringify(filteredData)
  const file = values.files[0]

  if (file && file.name !== '') {
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  const fskattesedel = values.fskattesedel[0]
  const trimmedFskattesedelName = trimPathInformation(fskattesedel.name)

  formData.append(
    'f-skattsedel',
    fskattesedel.fileStream,
    encodeRFC5987ValueChars(trimmedFskattesedelName)
  )

  formData.append('data', json)

  return formData
}
