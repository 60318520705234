import React, { FC } from 'react'

import { IRaknesnurra } from './Raknesnurra.types'
import RaknesnurraForm from './RaknesnurraForm'

const Raknesnurra: FC<IRaknesnurra> = ({
  content,
  dagPenningAkassaPerioder,
  inkomstbasbeloppPerioder,
  akasseBeloppPerioder,
}) => (
  <RaknesnurraForm
    content={content.raknesnurraForm}
    dagPenningAkassaPerioder={dagPenningAkassaPerioder}
    inkomstbasbeloppPerioder={inkomstbasbeloppPerioder}
    akasseBeloppPerioder={akasseBeloppPerioder}
  />
)

export default Raknesnurra
