import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { defaultErrorMessage } from '@local/src/Components/SystemFeedback/SystemFeedback'

import { ActivatePayments } from './ActivatePayments/ActivatePayments'
import { NextStepInformation } from './ActivatePayments/NextStepInformation'
import { RegisterAccount } from './Fragments/RegisterAccount'
import { INextStepAge } from './NextStepAge.types'
import { AktiveratAgeInformation } from './Fragments/AktiveratAgeInformation'

const NextStepAge: React.FunctionComponent<INextStepAge> = ({
  content,
  ageLoaded,
  arendeUpdated,
  formError,
  getAge,
  showSystemFeedback,
  harAktiveratAgeEjAvslutad,
  harAvslutadAge,
  harAgeEjStartad,
}) => {
  useEffect(() => {
    if (formError) {
      showSystemFeedback(defaultErrorMessage)
    }
  }, [formError, showSystemFeedback])

  useEffect(() => {
    if (arendeUpdated) {
      getAge({ noReload: true })
    }
  }, [getAge, arendeUpdated])

  const nextStepHeader = harAktiveratAgeEjAvslutad
    ? content.aktiveratAge.heading
    : content.aktiveraAge.heading

  const isVisible = ageLoaded && !harAvslutadAge

  if (!isVisible) return <></>

  return (
    <Box
      pt={4}
      pb={4}
      sx={(theme) => ({
        borderRadius: theme.spacing(0.5),
      })}
      data-testid="next-step-age"
    >
      {(harAgeEjStartad || harAktiveratAgeEjAvslutad) && (
        <Typography variant="h2" gutterBottom>
          {nextStepHeader}
        </Typography>
      )}

      {harAgeEjStartad && (
        <>
          <Typography variant="preamble">
            {content.aktiveraAge.mainIntro}
          </Typography>

          <RegisterAccount content={content.aktiveraAge} />
          <ActivatePayments content={content.aktiveraAge} />
          <NextStepInformation content={content.aktiveraAge} />
        </>
      )}

      {harAktiveratAgeEjAvslutad && (
        <>
          <AktiveratAgeInformation content={content.aktiveratAge} />
          <RegisterAccount content={content.aktiveraAge} />
        </>
      )}
    </Box>
  )
}

export default NextStepAge
