import React, { FC } from 'react'
import { Box, Link } from '@mui/material'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import { AgeStep, AgeStepsIconMap } from '../Fragments/AgeStep'
import { INextStep } from '../NextStepAge.types'

export const NextStepInformation: FC<INextStep> = ({ content }) => (
  <AgeStep
    header={content.automatiseradeUtbetalningarHeading}
    icon={AgeStepsIconMap.bulb}
    testSelector="next-step-information"
  >
    <>
      <HTMLMapper body={content.automatiseradeUtbetalningarText} />

      <Box mt={2} mb={4}>
        <Link href={content.hanteraAgeLink.url}>
          {content.hanteraAgeLink.text}
        </Link>
      </Box>
    </>
  </AgeStep>
)
