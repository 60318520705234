import * as React from 'react'
import { styled } from '@mui/material/styles'

type OffsetSize =
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64

interface IGutter {
  xs?: OffsetSize
  sm?: OffsetSize
  md?: OffsetSize
  lg?: OffsetSize
  xl?: OffsetSize
}

export interface IOffsetSizesMap {
  [index: number]: IGutter
}

const StyledGutter = styled('div')<IGutter>(
  ({ theme, xs, sm, md, lg, xl }) => ({
    height: xs,

    [theme.breakpoints.up('sm')]: {
      height: sm,
    },
    [theme.breakpoints.up('md')]: {
      height: md,
    },
    [theme.breakpoints.up('lg')]: {
      height: lg,
    },
    [theme.breakpoints.up('xl')]: {
      height: xl,
    },
  })
)

const Gutter = ({ xs = 32, sm, md, lg, xl }: IGutter) => (
  <StyledGutter xs={xs} sm={sm} md={md} lg={lg} xl={xl} />
)

export default Gutter
