import { add, differenceInBusinessDays } from 'date-fns'
import { groupBy, prop } from 'ramda'

import {
  AgePeriod,
  IAkasseAgePeriod,
  IAkasseBeloppPeriod,
  IBeraknadAge,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from './BeraknaAge.types'
import {
  GetInkomstbasbeloppPeriod,
  BeraknaMaxAge,
  BeraknaMojligAge,
  GetBeloppPerDag,
  GetTotalBelopp,
  convertFodelsedatumToDate,
  getMaxAkasseBeloppForDate,
  getAgeEnd,
} from './BeraknaAge.helpers'
import { beraknaAntaletAgePerioder } from './BeraknaAntalAgePerioder'

export const beraknaAgeperiod = ({
  manadslon,

  uppsagningsar,
  agePeriod,
  maxAkasseBelopp,
  startDay,
  lastDay,
  mojligaInkomstbasbelopp,
}: {
  manadslon: number

  uppsagningsar: number
  agePeriod: AgePeriod
  maxAkasseBelopp: number
  startDay: number
  lastDay: number
  mojligaInkomstbasbelopp: IInkomstbasbeloppPeriod[]
}): IBeraknadAge => {
  const inkomstbasbeloppPeriod: IInkomstbasbeloppPeriod =
    GetInkomstbasbeloppPeriod({
      mojligaInkomstbasbelopp: mojligaInkomstbasbelopp,
      uppsagningsar,
      agePeriod: agePeriod,
    })

  const maxAgeBelopp = BeraknaMaxAge({
    inkomstBasbelopp: inkomstbasbeloppPeriod?.inkomstBasbelopp,
    inkomstBasbeloppFaktor: inkomstbasbeloppPeriod?.ibbFaktor,
    dagarPerManad: inkomstbasbeloppPeriod?.dagarPerManad,
    ersattningsniva: inkomstbasbeloppPeriod?.ersattningsniva,
    dagpenningAkassa: maxAkasseBelopp,
  })

  const mojligAge = BeraknaMojligAge({
    manadslon: manadslon,
    dagarPerManad: inkomstbasbeloppPeriod?.dagarPerManad,
    ersattningsniva: inkomstbasbeloppPeriod?.ersattningsniva,
    dagpenningAkassa: maxAkasseBelopp,
  })

  const beloppPerDag = GetBeloppPerDag(mojligAge, maxAgeBelopp)

  const result = {
    period: agePeriod,
    startDay: startDay,
    lastDay: lastDay,
    beloppPerDag: beloppPerDag,
    beloppPerDagKey: beloppPerDag.toString(),
    totalBelopp: GetTotalBelopp(beloppPerDag, lastDay - startDay + 1),
    hasUppnatMaxtak: mojligAge > maxAgeBelopp,
  }

  return result
}

export const beraknaAge = ({
  manadslon,
  sistaAnstallningsdag,
  uppsagningsar,
  fodelsedatum,
  mojligaDagpenningar,
  mojligaInkomstbasbelopp,
  akasseBeloppPerioder,
}: {
  manadslon: number
  sistaAnstallningsdag: Date
  uppsagningsar: number
  fodelsedatum: string
  mojligaDagpenningar: IDagpenningAkassaPeriod[]
  mojligaInkomstbasbelopp: IInkomstbasbeloppPeriod[]
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
}): IBeraknadAge[] => {
  const birthdate = convertFodelsedatumToDate(fodelsedatum)

  const agePerioder = beraknaAntaletAgePerioder({
    manadslon,
    fodelsedatum: birthdate,
    sistaAnstallningsdag,
    mojligaDagpenningar,
  })

  const akassaAgePerioder = GetAkassaAgePerioder(
    agePerioder,
    sistaAnstallningsdag,
    akasseBeloppPerioder,
    birthdate
  )

  const beraknadeAgePeriods: IBeraknadAge[] = akassaAgePerioder.map(
    (akassaAgePeriod) =>
      beraknaAgeperiod({
        manadslon,
        uppsagningsar,
        agePeriod: akassaAgePeriod.agePeriod,
        maxAkasseBelopp: akassaAgePeriod.maxAkasseBelopp,
        startDay: akassaAgePeriod.startDay,
        lastDay: akassaAgePeriod.lastDay,
        mojligaInkomstbasbelopp,
      })
  )

  const groupedByBeloppPerDag = groupBy(
    prop('beloppPerDagKey'),
    beraknadeAgePeriods
  )
  const resultArray = MergeAgePeriods(groupedByBeloppPerDag)

  return resultArray
}

export const GetAkassaAgePerioder = (
  agePeriod: AgePeriod[],
  sistaAnstallningsdag: Date,
  akasseBeloppPerioder: IAkasseBeloppPeriod[],
  birthdate: Date
): IAkasseAgePeriod[] => {
  const akassaAgePerioder: IAkasseAgePeriod[] = []
  const ageStart = add(sistaAnstallningsdag, { days: 1 })
  const ageEnd = getAgeEnd(birthdate)
  const ageEndDay = differenceInBusinessDays(ageEnd, ageStart)

  let periodStartDay = 1
  let countWorkingDays = 0

  let currentAkasseBelopp = getMaxAkasseBeloppForDate(
    1,
    sistaAnstallningsdag,
    akasseBeloppPerioder
  )

  agePeriod.forEach((period) => {
    for (let i = 1; i <= 130; i++) {
      countWorkingDays = countWorkingDays + 1

      if (countWorkingDays > ageEndDay) {
        break
      }

      const date = add(ageStart, { days: i - 1 })

      const isAgeEnd = countWorkingDays === ageEndDay

      const nextMaxAkasseBelopp = getMaxAkasseBeloppForDate(
        countWorkingDays + 1,
        date,
        akasseBeloppPerioder
      )

      // console.log(
      // 	`${countWorkingDays} ${formatDate(
      // 		date
      // 	)} ${nextMaxAkasseBelopp} (${period}: ${i} av ${130})`
      // )

      const isNewAkasseBelopp = currentAkasseBelopp !== nextMaxAkasseBelopp

      if (isNewAkasseBelopp || i === 130 || isAgeEnd) {
        const lastDay = countWorkingDays

        const akasseAgePeriod: IAkasseAgePeriod = {
          startDay: periodStartDay,
          agePeriod: period,
          maxAkasseBelopp: currentAkasseBelopp,
          lastDay: lastDay,
        }

        akassaAgePerioder.push(akasseAgePeriod)

        periodStartDay = lastDay + 1
        currentAkasseBelopp = nextMaxAkasseBelopp
      }
    }
  })

  return akassaAgePerioder
}

const MergeAgePeriods = (groupedByBeloppPerDag: {
  [index: string]: IBeraknadAge[]
}) => {
  const agePeriods: IBeraknadAge[] = []

  Object.values(groupedByBeloppPerDag)?.forEach((period) => {
    //get min (earliest) startDay
    const startDay = period.reduce(
      (a, b) => (a.startDay < b.startDay ? a : b),
      { startDay: period[0]?.startDay }
    ).startDay

    //get max (latest) lastDay
    const lastDay = period.reduce((a, b) => (a.lastDay > b.lastDay ? a : b), {
      lastDay: 0,
    }).lastDay

    const periodTotalBelopp: number = period
      .map((o) => o.totalBelopp)
      .reduce((a, b) => a + b, 0)

    const mergedPeriod: IBeraknadAge = {
      ...period[0],
      startDay: startDay,
      lastDay: lastDay,
      totalBelopp: periodTotalBelopp,
    }

    agePeriods.push(mergedPeriod)
  })

  //sort by startDay: 1, 100, 130
  agePeriods.sort((a, b) => a.startDay - b.startDay)

  return agePeriods
}
