import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'
import { RootState } from '@local/Store/configureStore'

import { forsakraArbetssokande, setPending } from '../../Age.actions'
import { inkomstUppgift, age, foretagNamn } from '../../Age.selectors'

import ForsakraArbetssokande from './ForsakraArbetssokande'

const connectedComponent = connect(
  (state: RootState) => ({
    startDatum: age(state)?.startDatum,
    inkomstUppgift: inkomstUppgift(state),
    foretagNamn: foretagNamn(state),
  }),
  { forsakraArbetssokande, setPending },
  null,
  { context: AgeContext }
)(ForsakraArbetssokande)

export default connectedComponent
