import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'
import { RootState } from '@local/src/Store/configureStore'

import { andraSkatteavdrag, setPending } from '../../Age.actions'
import { skattesats } from '../../Age.selectors'

import AndraSkatteavdrag from './AndraSkatteavdrag'

const connectedComponent = connect(
  (state: RootState) => ({
    arendeSkattesats: skattesats(state),
  }),
  { andraSkatteavdrag, setPending },
  null,
  { context: AgeContext }
)(AndraSkatteavdrag)

export default connectedComponent
