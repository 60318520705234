import React, { FC } from 'react'
import { ExternalLink } from '@local/src/ExternalLink/ExternalLink'
import HTMLMapper from '@local/src/Components/HTMLMapper'

import { IRegisterAccount } from '../NextStepAge.types'

import { AgeStep, AgeStepsIconMap } from './AgeStep'

export const RegisterAccount: FC<IRegisterAccount> = ({ content }) => (
  <AgeStep
    header={content.registerAccountHeader}
    icon={AgeStepsIconMap.lock}
    testSelector="register-account"
  >
    <>
      <HTMLMapper body={content.registerAccountText ?? ''} />

      <ExternalLink
        href={content.registerAccountLink?.url}
        text={content.registerAccountLink?.text}
      />
    </>
  </AgeStep>
)
