import {
  compose,
  createStore,
  applyMiddleware,
  combineReducers,
  StoreEnhancer,
} from 'redux'
import thunk from 'redux-thunk'
import arende from '@local/Age/Age.reducers'
import rakneparametrar from '@local/Raknesnurra/Raknesnurra.reducers'
import systemfeedback from '@local/Components/SystemFeedback/SystemFeedback.reducers'

declare global {
  // eslint-disable-next-line
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reduxDevToolsOptions: any = {}

/* eslint-disable */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevToolsOptions)
    : compose
/* eslint-enable */

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const enhancer = composeEnhancers(applyMiddleware(thunk))
const rootReducer = combineReducers({
  arende,
  rakneparametrar,
  systemfeedback,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const configureStore = (preloadedState?: RootState) =>
  createStore(
    rootReducer,
    preloadedState,
    enhancer as StoreEnhancer<unknown, Record<string, unknown>>
  )

const store = configureStore()

export type RootState = ReturnType<typeof rootReducer>

export default store
