import { connect } from 'react-redux'
import { AgeContext } from '@local/Store/storeContext'
import { RootState } from '@local/Store/configureStore'

import { rapporteraAvgangsvederlag, setPending } from '../../Age.actions'
import { startDatum, sistaDagMedLon } from '../../Age.selectors'

import Avgangsvederlag from './Avgangsvederlag'

const connectedComponent = connect(
  (state: RootState) => ({
    startDatum: startDatum(state),
    sistaDagMedLon: sistaDagMedLon(state),
  }),
  { rapporteraAvgangsvederlag, setPending },
  null,
  { context: AgeContext }
)(Avgangsvederlag)

export default connectedComponent
