import * as React from 'react'
import { useFormik } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import EpiFile from '@local/Components/EpiFile'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import { Box, Grid2 as Grid } from '@mui/material'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useEffect } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IIntygaArbetssokandeAktivitetstod,
  IIntygaArbetssokandeAktivitetstodFormValues,
} from './IntygaArbetssokandeAktivitetsstod.types'
import { getFormData } from './IntygaArbetssokandeAktivitetsstod.helper'
import validationSchema from './IntygaArbetssokandeAktivitetsstod.schema'

const initialValues: IIntygaArbetssokandeAktivitetstodFormValues = {
  startDatum: null,
  slutDatum: null,
  filesAktivitetsstod: [],
  filesArbetssokande: [],
}

const IntygaArbetssokandeAktivitetstod = ({
  formName,
  content,
  handleCancel,
  intygaArbetssokandeAktivitetsstod,
  isExpanded,
  isPending,
  setPending,
}: IIntygaArbetssokandeAktivitetstod) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values)
    intygaArbetssokandeAktivitetsstod(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues: initialValues,
    validationSchema: validationSchema,
  })
  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const arbetssokandeUploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.arbetssokandedAttachmentInformationText
  )

  const aktivitetsstodUploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.aktivitetsstodAttachmentInformationText
  )

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <HTMLMapper body={content.informationText} />

      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <EpiFile
            relLink={content.arbetssokandeExampleFile}
            label={content.arbetssokandeExampleFileLabel}
          />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <FileUpload
            isLoadingUpload={false}
            content={arbetssokandeUploadContent}
            inputName={'filesArbetssokande'}
            files={formik.values.filesArbetssokande}
            setFieldValue={formik.setFieldValue}
            errorMessage={
              formik.touched.filesArbetssokande &&
              formik.errors.filesArbetssokande
                ? String(formik.errors.filesArbetssokande)
                : ''
            }
            testSelector="file-arbetssokande"
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }} mt={2}>
          <DatePicker
            label={content.fromDateLabel}
            value={formik.values.startDatum}
            name="startDatum"
            errorMessage={
              formik.touched.startDatum && formik.errors.startDatum !== ''
                ? (formik.errors.startDatum as string)
                : ''
            }
            onChange={customSetFieldValue(formik.setFieldValue, 'startDatum')}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}/>

        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePicker
            errorMessage={
              formik.touched.slutDatum && formik.errors.slutDatum !== ''
                ? (formik.errors.slutDatum as string)
                : ''
            }
            label={content.toDateLabel}
            minDate={formik.values.startDatum}
            value={formik.values.slutDatum}
            name="slutDatum"
            onChange={customSetFieldValue(formik.setFieldValue, 'slutDatum')}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}/>
      </Grid>

      <Box pt={4}>
        <EpiFile
          relLink={content.aktivitetsstodExampleFile}
          label={content.aktivitetsstodExampleFileLabel}
        />

        <FileUpload
          isLoadingUpload={false}
          content={aktivitetsstodUploadContent}
          inputName={'filesAktivitetsstod'}
          files={formik.values.filesAktivitetsstod}
          setFieldValue={formik.setFieldValue}
          errorMessage={
            formik.touched.filesAktivitetsstod &&
            formik.errors.filesAktivitetsstod
              ? String(formik.errors.filesAktivitetsstod)
              : ''
          }
          testSelector="file-aktivitetsstod"
        />
      </Box>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default IntygaArbetssokandeAktivitetstod
