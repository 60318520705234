import { ConnectedComponent } from 'react-redux'

import AndraSkatteavdrag from '../Forms/AndraSkatteavdrag'
import ArbetssokandeAvbrott from '../Forms/ArbetssokandeAvbrott'
import AteroppnatArende from '../Forms/AteroppnatArende'
import AtertagenUppsagning from '../Forms/AtertagenUppsagning'
import AvgangsVederlag from '../Forms/Avgangsvederlag'
import ForsakraArbetssokande from '../Forms/ForsakraArbetssokande'
import IntygaAktivitetsstod from '../Forms/IntygaAktivitetsstod'
import Lonegaranti from '../Forms/Lonegaranti'
import EkonomisktForetagsstod from '../Forms/EkonomisktForetagsstod'
import IntygaArbetssokande from '../Forms/IntygaArbetssokande'
import IntygaArbetssokandeAktivitetsstod from '../Forms/IntygaArbetssokandeAktivitetsstod'

export interface IHandelseBlock extends IHandelseBlockElement {
  content: unknown
  handleCancel: () => void
  isExpanded: boolean
  formName: string
}

export type IHandelseBlockElement = ConnectedComponent<
  React.ComponentType<unknown>,
  unknown
> | null

export interface IHandelse {
  availability: string

  block: IHandelseBlock
  title: string
  hasInkomstUppgift: boolean

  list?: {
    description: string
    header: string
  }
  form?: {
    attachmentInformationText: string
    dateInputFieldHeader: string
    informationText: string
  }
  category?: unknown
}

export interface IHandelseMap {
  readonly [key: string]: IHandelse
}

export const handelseMap: IHandelseMap = {
  andraRapporteratAvgangsvederlag: {
    availability: 'andraRapporteratAvgangsvederlag',
    block: AvgangsVederlag as IHandelseBlock,
    title: 'andraRapporteratAvgangsvederlag',
    hasInkomstUppgift: false,
  },
  andraSkatteavdrag: {
    availability: 'andraSkatteAvdrag',
    block: AndraSkatteavdrag as IHandelseBlock,
    title: 'andraSkatteavdrag',
    hasInkomstUppgift: false,
  },
  forsakraArbetssokande: {
    availability: 'forsakraArbetssokande',
    block: ForsakraArbetssokande as IHandelseBlock,
    title: 'forsakraArbetssokande',
    hasInkomstUppgift: true,
  },
  rapporteraArbetssokandeAvbrott: {
    availability: 'rapporteraArbetssokandeAvbrott',
    block: ArbetssokandeAvbrott as IHandelseBlock,
    title: 'rapporteraArbetssokandeAvbrott',
    hasInkomstUppgift: false,
  },
  rapporteraAteroppnatArende: {
    availability: 'rapporteraAteroppnatArende',
    block: AteroppnatArende as IHandelseBlock,
    title: 'rapporteraAteroppnatArende',
    hasInkomstUppgift: false,
  },
  rapporteraAtertagenUppsagning: {
    availability: 'rapporteraAtertagenUppsagning',
    block: AtertagenUppsagning as IHandelseBlock,
    title: 'rapporteraAtertagenUppsagning',
    hasInkomstUppgift: false,
  },
  rapporteraAvslutatEgetForetag: {
    availability: 'rapporteraAvslutatEgetForetag',
    block: null,
    title: 'rapporteraAvslutatEgetForetag',
    hasInkomstUppgift: false,
  },
  rapporteraNyttAvgangsvederlag: {
    availability: 'rapporteraNyttAvgangsvederlag',
    block: AvgangsVederlag as IHandelseBlock,
    title: 'rapporteraNyttAvgangsvederlag',
    hasInkomstUppgift: false,
  },
  rapporteraEkonomisktForetagsstod: {
    availability: 'rapporteraEkonomisktForetagsstod',
    block: EkonomisktForetagsstod as IHandelseBlock,
    title: 'rapporteraEkonomisktForetagsstod',
    hasInkomstUppgift: true,
  },
  rapporteraLonegaranti: {
    availability: 'rapporteraLonegaranti',
    block: Lonegaranti as IHandelseBlock,
    title: 'rapporteraLonegaranti',
    hasInkomstUppgift: false,
  },
  intygaAktivitetsstod: {
    availability: 'intygaAktivitetsstod',
    block: IntygaAktivitetsstod as IHandelseBlock,
    title: 'intygaAktivitetsstod',
    hasInkomstUppgift: true,
  },
  intygaArbetssokande: {
    availability: 'intygaArbetssokande',
    block: IntygaArbetssokande as IHandelseBlock,
    title: 'intygaArbetssokande',
    hasInkomstUppgift: false,
  },
  intygaArbetssokandeAktivitetsstod: {
    availability: 'intygaArbetssokandeAktivitetsstod',
    block: IntygaArbetssokandeAktivitetsstod as IHandelseBlock,
    title: 'intygaArbetssokandeAktivitetsstod',
    hasInkomstUppgift: false,
  },
}

export const handelseAvailability = Object.keys(handelseMap).map(
  (handelse: string) => handelseMap[handelse].availability
)

export const ageEvents = Object.keys(handelseMap).map(
  (x: string) => handelseMap[x].title
)
