import { path } from 'ramda'

import {
  IAkasseBeloppPeriod,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from './BeraknaAge/BeraknaAge.types'

export const ageRakneparametrarLoaded = path<boolean>([
  'rakneparametrar',
  'ageRakneparametrarLoaded',
])

export const dagPenningAkassaPerioder = path<IDagpenningAkassaPeriod[]>([
  'rakneparametrar',
  'dagPenningAkassaPerioder',
])
export const inkomstbasbeloppPerioder = path<IInkomstbasbeloppPeriod[]>([
  'rakneparametrar',
  'inkomstbasbeloppPerioder',
])
export const akasseBeloppPerioder = path<IAkasseBeloppPeriod[]>([
  'rakneparametrar',
  'akasseBeloppPerioder',
])
