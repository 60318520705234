import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import FileUpload from '@local/Components/FileUpload'
import EpiFile from '@local/Components/EpiFile'
import { generateErrorMessage } from '@local/Utils/Helpers/Forms/Forms.helpers'
import { isEmpty } from 'ramda'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import FormSubmit from '../../../Components/FormSubmit'

import {
  IIntygaArbetssokande,
  IIntygaArbetssokandeFormValues,
} from './IntygaArbetssokande.types'
import { getFormData, getLabels } from './IntygaArbetssokande.helper'
import validationSchema from './IntygaArbetssokande.schema'

const initialValues: IIntygaArbetssokandeFormValues = {
  files: [],
  arbetssokandeIntygTyp: 'intyg-arbetsformedling',
}

const IntygaArbetssokande = ({
  formName,
  content,
  dagarTillUtbetalning,
  setPending,
  intygaArbetssokande,
  handleCancel,
  isExpanded,
  isPending,
}: IIntygaArbetssokande) => {
  const customEventTracking = useTrackCustomClick()

  const formSubmit = async () => {
    const formData = getFormData(formik.values)

    intygaArbetssokande(formData)
    setSubmitting(false)

    await formik.setTouched({})
    await formik.setFieldValue('files', null)

    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues: initialValues,
    validationSchema: validationSchema,
  })
  const { setSubmitting, handleChange, setFieldValue } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const labels = getLabels(formik.values.arbetssokandeIntygTyp, content)

  const replaceValues = {
    '{DagarTillUtbetalning}': dagarTillUtbetalning,
  }

  const handleArbetssokandeIntygTypChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLElement>) => {
      handleChange(e)
      await setFieldValue('files', [])
    },
    [handleChange, setFieldValue]
  )

  const isFileUploadVisible = !isEmpty(formik.values.arbetssokandeIntygTyp)
  const arbetssokandeIntygTypError = generateErrorMessage({
    selector: 'arbetssokandeIntygTyp',
    errorMsg: formik.errors.arbetssokandeIntygTyp ?? '',
    touched: formik.touched,
  })
  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    labels.attachmentLabel
  )

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <Box>
        <HTMLMapper
          body={labels.informationText}
          replaceValues={replaceValues}
        />
      </Box>

      <RadioGroup
        name="arbetssokandeIntygTyp"
        value={formik.values.arbetssokandeIntygTyp}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        <FormControlLabel
          value="intyg-arbetsformedling"
          control={<Radio />}
          label="Intyg från arbetsförmedling"
          data-testid="intyg-arbetsformedling"
          onChange={void handleArbetssokandeIntygTypChange}
        />
        <FormControlLabel
          value="specifikation-om-ersattning"
          control={<Radio />}
          data-testid="specifikation-om-ersattning"
          label="Specifikation om ersättning från a-kassa"
          onChange={void handleArbetssokandeIntygTypChange}
        />

        <FormHelperText error={Boolean(arbetssokandeIntygTypError)}>
          {arbetssokandeIntygTypError}
        </FormHelperText>
      </RadioGroup>

      {isFileUploadVisible && (
        <>
          <EpiFile
            relLink={labels.exampleFile}
            label={labels.exampleFileLabel}
          />

          <FileUpload
            isLoadingUpload={false}
            content={uploadContent}
            inputName={'files'}
            files={formik.values.files}
            setFieldValue={setFieldValue}
            errorMessage={
              formik.touched.files && formik.errors.files
                ? String(formik.errors.files)
                : ''
            }
            testSelector="file-upload"
          />
        </>
      )}
      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default IntygaArbetssokande
