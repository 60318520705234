import { intervalToDuration } from 'date-fns'

import { convertToDateWithoutTime } from '../Raknesnurra.helper'

import { AgePeriod, IDagpenningAkassaPeriod } from './BeraknaAge.types'

export const beraknaAntaletAgePerioder = ({
  manadslon,
  fodelsedatum,
  sistaAnstallningsdag,
  mojligaDagpenningar,
}: {
  manadslon: number
  fodelsedatum: Date
  sistaAnstallningsdag: Date
  mojligaDagpenningar: IDagpenningAkassaPeriod[]
}): AgePeriod[] => {
  const ageStart = convertToDateWithoutTime(sistaAnstallningsdag)

  let mojligaAgeperioder = mojligaDagpenningar
    .filter(
      (mojligDagpenning) =>
        convertToDateWithoutTime(mojligDagpenning.giltigtFrom) <= ageStart &&
        convertToDateWithoutTime(mojligDagpenning.giltigtTom) >= ageStart &&
        mojligDagpenning.minLonForAgePeriod <= manadslon
    )
    .map((mojligDagpenning) => mojligDagpenning.agePeriod)

  const alder = intervalToDuration({
    start: fodelsedatum,
    end: sistaAnstallningsdag,
  }).years

  const minAlderFor1AGEPeriod = 40
  const minAlderFor2AGEPeriods = 45
  const maxAlderFor2AGEPeriods = 60

  if (alder >= minAlderFor1AGEPeriod && alder < minAlderFor2AGEPeriods)
    mojligaAgeperioder = mojligaAgeperioder.filter(
      (mojligAgeperiod) =>
        mojligAgeperiod !== 'AGE2' && mojligAgeperiod !== 'AGE3'
    )

  if (alder >= minAlderFor2AGEPeriods && alder < maxAlderFor2AGEPeriods)
    mojligaAgeperioder = mojligaAgeperioder.filter(
      (mojligAgeperiod) => mojligAgeperiod !== 'AGE3'
    )

  return mojligaAgeperioder
}
