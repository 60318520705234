import { omit } from 'ramda'
import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import { IAndraSkatteavdragFormValues } from './AndraSkatteavdrag.types'

export const getFormData = (values: IAndraSkatteavdragFormValues): FormData => {
  const json = JSON.stringify(omit(['files'], values))
  const formData = new FormData()

  if (values.skattesats < 30) {
    const file = values.files[0]
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  formData.append('data', json)

  return formData
}
