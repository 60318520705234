import * as React from 'react'
import { trrFormat } from '@local/Utils/Helpers/formatDate'
import { Grid2 as Grid, Typography } from '@mui/material'

import { IMyAgeContent } from '../MyAge.types'

interface IErsattningsDagar {
  maxErsattningsDagar: number
  utbetaldaDagar: number
  startDatum: Date
  content: IMyAgeContent
}

const ErsattningsDagar: React.FunctionComponent<
  React.PropsWithChildren<IErsattningsDagar>
> = ({ maxErsattningsDagar, utbetaldaDagar, content, startDatum }) => {
  const {
    ageStartText,
    maxErsattningsDagarText,
    utbetaldaDagarText,
    ersattningsDagarHeader,
  } = content

  return (
    <Grid container spacing={1} mb={1}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h3">{ersattningsDagarHeader}</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="body2">
          {ageStartText}
          <br />
          <strong>{trrFormat(startDatum)}</strong>
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="body2">
          {maxErsattningsDagarText}
          <br />
          <strong>{maxErsattningsDagar} dagar</strong>
        </Typography>
      </Grid>

      <Grid size={{ xs: 12 }}>
        {
          <Typography variant="body2">
            {utbetaldaDagarText}
            <br />
            <strong>{utbetaldaDagar} dagar</strong>
          </Typography>
        }
      </Grid>
    </Grid>
  )
}

export default ErsattningsDagar
