import * as React from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

interface InformationPopperProps {
  id: string
  text: string
}

const InformationPopper: React.FunctionComponent<InformationPopperProps> = ({
  id,
  text,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <Box sx={{ alignSelf: 'flex-start' }} data-testid={id}>
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Button variant="text" onClick={handleClick} sx={{ padding: 1 }}>
          <InfoIcon color="primary" />
        </Button>
      </ClickAwayListener>

      <Popper open={open} anchorEl={anchorEl}>
        <Box
          p={2}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            bgcolor: 'white',
            maxWidth: '400px',
          })}
        >
          <Stack spacing={1}>
            <Typography variant="body2">{text}</Typography>
          </Stack>
        </Box>
      </Popper>
    </Box>
  )
}

export default InformationPopper
