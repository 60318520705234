import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import { IIntygaArbetssokandeAktivitetstodFormValues } from './IntygaArbetssokandeAktivitetsstod.types'

export const getFormData = (
  values: IIntygaArbetssokandeAktivitetstodFormValues
) => {
  const json = JSON.stringify({
    aktivitetsstod: {
      startDatum: values.startDatum,
      slutDatum: values.slutDatum,
    },
  })
  const formData = new FormData()
  const fileArbetssokande = values.filesArbetssokande[0]
  const fileAktivitetsstod = values.filesAktivitetsstod[0]

  const trimmedFileNameArbetssokande = trimPathInformation(
    fileArbetssokande?.name ?? ''
  )
  const trimmedFileNameAktivitetsstod = trimPathInformation(
    fileAktivitetsstod?.name ?? ''
  )
  formData.append(
    'intyg-arbetssokande',
    fileArbetssokande.fileStream,
    encodeRFC5987ValueChars(trimmedFileNameArbetssokande)
  )
  formData.append(
    'intyg-aktivitetsstod',
    fileAktivitetsstod.fileStream,
    encodeRFC5987ValueChars(trimmedFileNameAktivitetsstod)
  )
  formData.append('data', json)

  return formData
}
