/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormikHelpers as FormikActions, FormikValues } from 'formik'
import {
  pipe,
  split,
  map,
  head,
  tail,
  juxt,
  join,
  compose,
  toUpper,
  toLower,
} from 'ramda'

import { trrDateUTC } from './formatDate'

type MapToOutput<I, U = I> = (v: I) => U

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
  new Intl.NumberFormat('sv-se', {
    style: 'currency',
    currency: 'SEK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(amount)
    //eslint-disable-next-line
    .replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored

export const isLinkExternal: MapToOutput<string, boolean> = (href) =>
  href.startsWith('http')

export const setFieldValue =
  (
    _setFieldValue: FormikActions<FormikValues>['setFieldValue'],
    fieldName: string
  ) =>
  (value: Date | string): void => {
    const wrappedValue = value instanceof Date ? trrDateUTC(value) : value
    void _setFieldValue(fieldName, wrappedValue)
  }

export const setFieldTouched =
  (
    _setFieldTouched: FormikActions<FormikValues>['setFieldTouched'],
    fieldName: string,
    isTouched: boolean
  ) =>
  (): void =>
    void _setFieldTouched(fieldName, isTouched)

export const trace =
  (log: string) =>
  (x: string): string => {
    /* eslint-disable-next-line */
    console.log(log, x)
    return x
  }

export const capitalize: MapToOutput<any, string> = pipe<any, any, string>(
  juxt([compose(toUpper, head), tail]),
  join('')
)

export const headRest = (list: string[]): any => ({
  first: pipe(head, toLower)(list),
  rest: pipe(tail, map(capitalize), join(''))(list),
})

export const mergeString = ({
  first,
  rest,
}: {
  first: string
  rest: string
}): string => `${first}${rest}`

const baseCamelcase = (reg: RegExp | string) =>
  pipe<any[], string[], { first: string; rest: string }, any>(
    split(reg),
    headRest,
    mergeString
  )

export const kebabToCamelCase = baseCamelcase('-')

const toDigits = (numString: string) =>
  numString.replace(/\D/g, '').split('').map(Number)

export const validateOrganizationNumber = (
  organizationNumber: string
): boolean => {
  const digits = toDigits(organizationNumber)
  const checkDigit = digits[digits.length - 1]
  const total = digits
    .slice(0, -1)
    .reverse()
    .map((current: number, index: number) =>
      index % 2 === 0 ? current * 2 : current
    )
    .map((current: number) => (current > 9 ? current - 9 : current))
    .reduce(
      (current: number, accumulator: number) => current + accumulator,
      checkDigit
    )
  return total % 10 === 0
}

// will convert all the keys on the first level to lower case
export const objectFirstLevelToLowerCase = (
  // Wanna be able to pass any object
  /* eslint-disable-next-line */
  object: Record<string, any>
): Record<string, unknown> =>
  Object.keys(object).reduce(
    (destination: Record<string, unknown>, key: string) => {
      destination[key.toLowerCase()] = object[key]
      return destination
    },
    {}
  )

export const pickNumbersFromString = (input: string): string =>
  input?.replace(/\D/g, '')
