import {
  encodeRFC5987ValueChars,
  trimPathInformation,
} from '@local/src/Utils/Helpers/fileHelpers'

import { ILonegarantiFormValues } from './Lonegaranti.types'

export const getFormData = (values: ILonegarantiFormValues) => {
  const formData = new FormData()
  const file = values.files[0]

  if (file) {
    const trimmedFileName = trimPathInformation(file.name)
    formData.append(
      'file',
      file.fileStream,
      encodeRFC5987ValueChars(trimmedFileName)
    )
  }

  return formData
}
