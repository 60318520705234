import React, { useEffect } from 'react'
import { useDispatch } from '@local/Store/storeContext'
import { useSignalR } from '@trr/app-shell-data'
import {
  connectToSignalRRoom,
  disconnectFromSignalRRoom,
} from '@local/src/SignalR/signalRHelpers'

import getConfig from '../getConfig'

const SignalRWrapperHelper: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const dispatch = useDispatch()
  const connection = useSignalR(`${getConfig().PUBLIC_WEB_API_URL}/klient`)

  useEffect(() => {
    connectToSignalRRoom({
      connection: connection,
      room: 'Notification',
      dispatch,
    })
    connectToSignalRRoom({
      connection: connection,
      room: 'KlientArendeUpdated',
      dispatch,
    })

    return () => {
      disconnectFromSignalRRoom({
        connection: connection,
        room: 'KlientArendeUpdated',
      })
      disconnectFromSignalRRoom({
        connection: connection,
        room: 'Notification',
      })
    }
  }, [dispatch, connection])

  return <>{children}</>
}

export default SignalRWrapperHelper
