import { createContext } from 'react'
import {
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
} from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppDispatch = ThunkDispatch<unknown, unknown, any>

export const AgeContext = createContext<ReactReduxContextValue<unknown>>(
  {} as ReactReduxContextValue<unknown>
)

export const useStore = createStoreHook(AgeContext)
export const useDispatch = createDispatchHook(AgeContext)
export const useSelector = createSelectorHook(AgeContext)
