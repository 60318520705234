import React from 'react'
import { HTMLMapper as PackageHTMLMapper } from '@trr/html-mapper'
import getConfig from '@local/src/Utils/getConfig'

interface HTMLMapperProps {
  body: string
  replaceValues?: Record<string, unknown>
  className?: string
}

const HTMLMapper = ({
  body = '',
  replaceValues,
  className,
}: HTMLMapperProps) => (
  <PackageHTMLMapper
    mediaUrl={getConfig().MEDIA_URL}
    body={body}
    replaceValues={replaceValues}
    className={className}
  />
)

export default HTMLMapper
