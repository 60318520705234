import React, { useEffect } from 'react'
import {
  AppDispatch,
  useDispatch,
  useSelector,
} from '@local/Store/storeContext'
import { useEpiContent } from '@trr/app-shell-data'
import { getAge, cleanAge, getSkrivUtBeslut } from '@local/Age/Age.actions'
import MyAge from '@local/Age/MyAge'
import NextStepAge from '@local/Age/NextStepAge'
import { IMyAgeContent } from '@local/Age/MyAge/MyAge.types'
import {
  IAktiveraEpiContent,
  IAktiveratEpiContent,
} from '@local/Age/NextStepAge/NextStepAge.types'
import { ISkrivUtBeslutContent } from '@local/Age/SkrivUtBeslut/SkrivUtBeslut.types'
import {
  ageLoaded as ageLoadedSelector,
  kanSkrivaUtAgeBeslut as kanSkrivaUtAgeBeslutSelector,
} from '@local/Age/Age.selectors'

import { IContent } from '../App.types'

export interface IActivateAgeContent {
  aktiveraAge: IAktiveraEpiContent
  myAge: IMyAgeContent
  skrivUtBeslut: ISkrivUtBeslutContent
  aktiveratAge: IAktiveratEpiContent
  heading: string
}

const ActivateAge: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()

  const content = useEpiContent<IContent>()
  const contentLoading = !('aktiveratAge' in content)
  const kanSkrivaUtAgeBeslut = useSelector(kanSkrivaUtAgeBeslutSelector)
  const ageLoaded = useSelector(ageLoadedSelector)

  useEffect(() => {
    void dispatch(getAge())
    return () => void dispatch(cleanAge())
  }, [dispatch])

  useEffect(() => {
    if (kanSkrivaUtAgeBeslut) {
      void dispatch(getSkrivUtBeslut())
    }
  }, [dispatch, kanSkrivaUtAgeBeslut])

  return !contentLoading && ageLoaded ? (
    <>
      <MyAge
        content={{
          ...content.aktiveraMinAge.myAge,
          skrivUtBeslut: content.skrivUtBeslut,
          heading: content.heading,
        }}
      />
      <NextStepAge content={content} />
    </>
  ) : null
}

export default ActivateAge
