import React from 'react'
import { ActivateAge, HandleAge, Raknesnurra } from '@local/src/Pages'
import { useCurrentKey } from '@trr/app-shell-data'

import SystemFeedback from './Components/SystemFeedback/SystemFeedback'

const MainContent = () => {
  const activatePaymentsKey = 'aktivera-min-age'
  const handleAgeKey = 'hantera-min-age'
  const raknesnurra = 'age-raknesnurra'
  const currentKey = useCurrentKey()

  return (
    <>
      <SystemFeedback />
      {currentKey === activatePaymentsKey && <ActivateAge />}
      {currentKey === handleAgeKey && <HandleAge />}
      {currentKey === raknesnurra && <Raknesnurra />}
    </>
  )
}

export default MainContent
