import { useSelector } from '@local/src/Store/storeContext'
import {
  startDatum as startDatumSelector,
  lockForsakraArbetssokande as lockForsakraArbetssokandeSelector,
  enableRapporteraEkonomisktForetagsstod as enableRapporteraEkonomisktForetagsstodSelector,
  lockRapporteraEkonomisktForetagsstod as lockRapporteraEkonomisktForetagsstodSelector,
  enableForsakraArbetssokande as enableForsakraArbetssokandeSelector,
} from '@local/Age/Age.selectors'

export const useActivatePaymentsData = () => {
  const startDatum = useSelector(startDatumSelector)
  const lockForsakraArbetssokande = useSelector(
    lockForsakraArbetssokandeSelector
  )
  const enableRapporteraEkonomisktForetagsstod = useSelector(
    enableRapporteraEkonomisktForetagsstodSelector
  )
  const lockRapporteraEkonomisktForetagsstod = useSelector(
    lockRapporteraEkonomisktForetagsstodSelector
  )

  const enableForsakraArbetssokande = useSelector(
    enableForsakraArbetssokandeSelector
  )

  return {
    startDatum,
    lockForsakraArbetssokande,
    enableRapporteraEkonomisktForetagsstod,
    enableForsakraArbetssokande,
    lockRapporteraEkonomisktForetagsstod,
  }
}

export default useActivatePaymentsData
