import * as Yup from 'yup'
import { IUniqueFile } from '@local/src/Components/FileUpload/FileUpload.types'
import { isAfter } from 'date-fns'
import { isNil } from 'ramda'

import { IAvgangsvederlagFormValues } from './Avgangsvederlag.types'

const validationSchema = (startDatum: string) =>
  Yup.lazy((values: IAvgangsvederlagFormValues) =>
    Yup.object().shape({
      vederlagsDatum: Yup.date()
        .nullable()
        .required('Du måste ange vederlagsdatum')
        .typeError('Du måste ange ett giltigt vederlagsdatum'),

      files: Yup.mixed().test(
        'files',
        'Du måste bifoga beslut om ersättning',
        (files: IUniqueFile[]) =>
          isAfter(new Date(values.vederlagsDatum), new Date(startDatum)) ||
          !isNil(files[0])
      ),
    })
  )

export default validationSchema
