import * as React from 'react'
import { useFormik } from 'formik'
import HTMLMapper from '@local/src/Components/HTMLMapper'
import FileUpload from '@local/Components/FileUpload'
import { setFieldValue as customSetFieldValue } from '@local/Utils/Helpers'
import { DatePicker } from '@local/src/Components/DatePicker'
import { fileUploadContent } from '@local/src/Components/FileUpload/fileUpload.helpers'
import { Grid2 as Grid } from '@mui/material'
import EpiFile from '@local/src/Components/EpiFile'
import useResetForm from '@local/src/Utils/Hooks/useResetForm'
import { useEffect } from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import MinainkomstUppgiftForm from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm'
import FormSubmit from '../../../Components/FormSubmit'
import { IMinaInkomstUppgifterFormContent } from '../Common/MinaInkomstUppgifterForm/MinaInkomstUppgifterForm.types'
import { IInkomstuppgifter } from '../../MyAge/MyAge.types'

import {
  IIntygaAktivitetstod,
  IIntygaAktivitetstodFormValues,
} from './IntygaAktivitetsstod.types'
import { getFormData } from './IntygaAktivitetsstod.helper'
import validationSchema from './IntygaAktivitetsstod.schema'

const getInitialValues = (
  inkomstUppgift: IInkomstuppgifter
): IIntygaAktivitetstodFormValues => ({
  startDatum: null,
  slutDatum: null,
  files: [],
  inkomstUppgift,
})

export const Aktivitetstod = ({
  formName,
  content,
  foretagNamn,
  inkomstUppgift,
  isExpanded,
  isPending,
  intygaAktivitetstod,
  setPending,
  handleCancel,
}: IIntygaAktivitetstod) => {
  const initialValues = getInitialValues(inkomstUppgift)
  const customEventTracking = useTrackCustomClick()

  const formSubmit = () => {
    const formData = getFormData(formik.values)
    intygaAktivitetstod(formData)
    setPending(true)

    customEventTracking(formName, {
      label: 'skicka',
    })
  }

  const formik = useFormik({
    onSubmit: formSubmit,
    initialValues,
    validationSchema: validationSchema({ inkomstUppgift }),
  })
  const { setSubmitting } = formik

  useEffect(() => {
    setSubmitting(false)
  }, [isPending, setSubmitting])

  useResetForm(isExpanded, formik.resetForm, initialValues)

  const changeLoneRevisionsDatum = (value: Date) => {
    void formik.setFieldValue('inkomstUppgift.lonerevisionsDatum', value)
  }

  const uploadContent = fileUploadContent(
    content.attachmentButtonText,
    content.attachmentInformationText
  )

  return (
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
      <HTMLMapper body={content.informationText} />

      {!inkomstUppgift.harUppdateratLon && (
        <MinainkomstUppgiftForm
          handleChangeDate={changeLoneRevisionsDatum}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values.inkomstUppgift}
          content={content.inkomstUppgift as IMinaInkomstUppgifterFormContent}
          errors={formik.errors.inkomstUppgift}
          foretagNamn={foretagNamn}
        />
      )}

      <Grid container spacing={2} pt={2}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePicker
            label={content.fromDateLabel}
            value={formik.values.startDatum}
            maxDate={formik.values.slutDatum}
            name="startDatum"
            errorMessage={
              formik.touched.startDatum && formik.errors.startDatum !== ''
                ? (formik.errors.startDatum as string)
                : ''
            }
            onChange={customSetFieldValue(formik.setFieldValue, 'startDatum')}
          />
        </Grid>

        <Grid size={{ xs: 12 }} />

        <Grid size={{ xs: 12, sm: 6 }}>
          <DatePicker
            name="slutDatum"
            value={formik.values.slutDatum}
            label={content.toDateLabel}
            minDate={formik.values.startDatum}
            onChange={customSetFieldValue(formik.setFieldValue, 'slutDatum')}
            errorMessage={
              formik.touched.slutDatum && formik.errors.slutDatum !== ''
                ? (formik.errors.slutDatum as string)
                : ''
            }
          />
        </Grid>

        <Grid size={{ xs: 12 }} />

        <Grid size={{ xs: 12 }}>
          <EpiFile
            relLink={content.exampleFile}
            label={content.exampleFileLabel}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 6 }}>
          <FileUpload
            isLoadingUpload={false}
            content={uploadContent}
            inputName={'files'}
            files={formik.values.files}
            setFieldValue={formik.setFieldValue}
            errorMessage={
              formik.touched.files && formik.errors.files
                ? String(formik.errors.files)
                : ''
            }
          />
        </Grid>
      </Grid>

      <FormSubmit
        submitText={content.buttonText}
        submitAction={formik.handleSubmit}
        cancelAction={handleCancel}
      />
    </form>
  )
}

export default Aktivitetstod
