import {
  IAkasseBeloppPeriod,
  IDagpenningAkassaPeriod,
  IInkomstbasbeloppPeriod,
} from '@local/Raknesnurra/BeraknaAge/BeraknaAge.types'
import { format } from 'date-fns'

import { trrDate } from '../Utils/Helpers/formatDate'

export const ConvertDagPenningAkassaDates = (
  dagpenningAkassaPerioder: IDagpenningAkassaPeriod[]
): IDagpenningAkassaPeriod[] =>
  dagpenningAkassaPerioder.map((period) => {
    period.giltigtFrom = trrDate(period.giltigtFrom)
    period.giltigtTom = trrDate(period.giltigtTom)
    return period
  })

export const ConvertInkomstbasbeloppDates = (
  inkomstbasbeloppPerioder: IInkomstbasbeloppPeriod[]
): IInkomstbasbeloppPeriod[] =>
  inkomstbasbeloppPerioder.map((period: IInkomstbasbeloppPeriod) => {
    period.giltigtFrom = trrDate(period.giltigtFrom)
    period.giltigtTom = trrDate(period.giltigtTom)
    return period
  })

export const ConvertAkasseBeloppDates = (
  akasseBeloppPerioder: IAkasseBeloppPeriod[]
): IAkasseBeloppPeriod[] =>
  akasseBeloppPerioder.map((period: IAkasseBeloppPeriod) => {
    period.giltigtFrom = trrDate(period.giltigtFrom)
    period.giltigtTom = trrDate(period.giltigtTom)
    return period
  })

export const convertToDateWithoutTime = (date: string | Date) =>
  format(new Date(date), 'yyyy-MM-dd')
